export const ActionType = {

    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',

    //Login
    RESET_LOADING: 'RESET_LOADING',
    LOGIN_USER_BEGIN: 'LOGIN_USER_BEGIN',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',

    //Registration
    REGISTER_USER_BEGIN: 'REGISTER_USER_BEGIN',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',

    GET_REGISTRAION_VERIFICATION_BEGIN: 'GET_REGISTRAION_VERIFICATION_BEGIN',
    GET_REGISTRAION_VERIFICATION_SUCCESS: 'GET_REGISTRAION_VERIFICATION_SUCCESS',
    GET_REGISTRAION_VERIFICATION_ERROR: 'GET_REGISTRAION_VERIFICATION_ERROR',
    SWITCH_REGISTRATION_VERIFICATION_BEGIN: 'SWITCH_REGISTRATION_VERIFICATION_BEGIN',
    SWITCH_REGISTRATION_VERIFICATION_SUCCESS: 'SWITCH_REGISTRATION_VERIFICATION_SUCCESS',
    SWITCH_REGISTRATION_VERIFICATION_ERROR: 'SWITCH_REGISTRATION_VERIFICATION_ERROR',

    // Set Password
    SET_PASSWORD_BEGIN: 'SET_PASSWORD_BEGIN',
    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_PASSWORD_ERROR: 'SET_PASSWORD_ERROR',
    CHECK_HASH_BEGIN: 'CHECK_HASH_BEGIN',
    CHECK_HASH_SUCCESS: 'CHECK_HASH_SUCCESS',
    CHECK_HASH_ERROR: 'CHECK_HASH_ERROR',

    /* ACCOUNT VERIFICATION */

    NOT_VERIFIED_ACCOUNT: 'NOT_VERIFIED_ACCOUNT',

    //Email verification
    VERIFY_ACCOUNT_EMAIL_BEGIN: 'VERIFY_ACCOUNT_EMAIL_BEGIN',
    VERIFY_ACCOUNT_EMAIL_SUCCESS: 'VERIFY_ACCOUNT_EMAIL_SUCCESS',
    VERIFY_ACCOUNT_EMAIL_FAILURE: 'VERIFY_ACCOUNT_EMAIL_FAILURE',

    //Re-send verification code
    RESEND_EMAIL_VERIFICATION_CODE_BEGIN: 'RESEND_EMAIL_VERIFICATION_CODE_BEGIN',
    RESEND_EMAIL_VERIFICATION_CODE_SUCCESS: 'RESEND_EMAIL_VERIFICATION_CODE_SUCCESS',
    RESEND_EMAIL_VERIFICATION_CODE_FAILURE: 'RESEND_EMAIL_VERIFICATION_CODE_FAILURE',

    //Mobile verification
    VERIFY_ACCOUNT_MOBILE_BEGIN: 'VERIFY_ACCOUNT_MOBILE_BEGIN',
    VERIFY_ACCOUNT_MOBILE_SUCCESS: 'VERIFY_ACCOUNT_MOBILE_SUCCESS',
    VERIFY_ACCOUNT_MOBILE_FAILURE: 'VERIFY_ACCOUNT_MOBILE_FAILURE',

    //Re-send verification code
    RESEND_MOBILE_VERIFICATION_CODE_BEGIN: 'RESEND_MOBILE_VERIFICATION_CODE_BEGIN',
    RESEND_MOBILE_VERIFICATION_CODE_SUCCESS: 'RESEND_MOBILE_VERIFICATION_CODE_SUCCESS',
    RESEND_MOBILE_VERIFICATION_CODE_FAILURE: 'RESEND_MOBILE_VERIFICATION_CODE_FAILURE',

    // Two factor auth

    GENERATE_TFA_CODE_BEGIN: 'GENERATE_TFA_CODE_BEGIN',
    GENERATE_TFA_CODE_SUCCESS: 'GENERATE_TFA_CODE_SUCCESS',
    GENERATE_TFA_CODE_FAILURE: 'GENERATE_TFA_CODE_FAILURE',

    RECOVER_TFA_BEGIN: 'RECOVER_TFA_BEGIN',
    RECOVER_TFA_SUCCESS: 'RECOVER_TFA_SUCCESS',
    RECOVER_TFA_FAILURE: 'RECOVER_TFA_FAILURE',

    /* PROFILE */

    //Get User Data
    GET_USER_DATA_BEGIN: 'GET_USER_DATA_BEGIN',
    GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
    GET_USER_DATA_ERROR: 'GET_USER_DATA_ERROR',

    // Change Email Address
    SEND_VERIFICATION_EMAIL_BEGIN: 'SEND_VERIFICATION_EMAIL_BEGIN',
    SEND_VERIFICATION_EMAIL_SUCCESS: 'SEND_VERIFICATION_EMAIL_SUCCESS',
    SEND_VERIFICATION_EMAIL_FAILURE: 'SEND_VERIFICATION_EMAIL_FAILURE',

    // Verify Changed Email
    VERIFY_CHANGED_EMAIL_BEGIN: 'VERIFY_CHANGED_EMAIL_BEGIN',
    VERIFY_CHANGED_EMAIL_SUCCESS: 'VERIFY_CHANGED_EMAIL_SUCCESS',
    VERIFY_CHANGED_EMAIL_FAILURE: 'VERIFY_CHANGED_EMAIL_FAILURE',

    // Verify Changed Email
    VERIFY_CHANGED_MOBILE_BEGIN: 'VERIFY_CHANGED_MOBILE_BEGIN',
    VERIFY_CHANGED_MOBILE_SUCCESS: 'VERIFY_CHANGED_MOBILE_SUCCESS',
    VERIFY_CHANGED_MOBILE_FAILURE: 'VERIFY_CHANGED_MOBILE_FAILURE',

    //Change Password
    CHANGE_PASSWORD_BEGIN: 'CHANGE_PASSWORD_BEGIN',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    //Change fullname
    CHANGE_FULLNAME_BEGIN: 'CHANGE_FULLNAME_BEGIN',
    CHANGE_FULLNAME_SUCCESS: 'CHANGE_FULLNAME_SUCCESS',
    CHANGE_FULLNAME_ERROR: 'CHANGE_FULLNAME_ERROR',

    // Get companies
    GET_COMPANIES_BEGIN: 'GET_COMPANIES_BEGIN',
    GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
    GET_COMPANIES_ERROR: 'GET_COMPANIES_ERROR',
    GET_ALL_COMPANIES_BEGIN: 'GET_ALL_COMPANIES_BEGIN',
    GET_ALL_COMPANIES_SUCCESS: 'GET_ALL_COMPANIES_SUCCESS',
    GET_ALL_COMPANIES_ERROR: 'GET_ALL_COMPANIES_ERROR',

    /* PROJECTS */

    //Get Projects
    GET_PROJECTS_BEGIN: 'GET_PROJECTS_BEGIN',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
    GET_PROJECTS_ERROR: 'GET_PROJECTS_ERROR',
    //Get Project by id
    GET_PROJECT_BEGIN: 'GET_PROJECT_BEGIN',
    GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
    GET_PROJECT_ERROR: 'GET_PROJECT_ERROR',
    RESET_SELECTED_PROJECT: 'RESET_SELECTED_PROJECT',

    RESET_PROJECT_DATA: 'RESET_PROJECT_DATA',
    CHANGE_PROJECS_NAME_BEGIN: 'CHANGE_PROJECS_NAME_BEGIN',
    CHANGE_PROJECS_NAME_SUCCESS: 'CHANGE_PROJECS_NAME_SUCCESS',
    CHANGE_PROJECS_NAME_ERROR: 'CHANGE_PROJECS_NAME_ERROR',

    //Check project name
    CHECK_PROJECT_NAME_BEGIN: 'CHECK_PROJECT_NAME_BEGIN',
    CHECK_PROJECT_NAME_SUCCESS: 'CHECK_PROJECT_NAME_SUCCESS',
    CHECK_PROJECT_NAME_ERROR: 'CHECK_PROJECT_NAME_ERROR',

    //Delete Project
    DELETE_PROJECT_BEGIN: 'DELETE_PROJECT_BEGIN',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_ERROR: 'DELETE_PROJECT_ERROR',

    //Change Project status
    CHANGE_PROJECT_STATUS_BEGIN: 'CHANGE_PROJECT_STATUS_BEGIN',
    CHANGE_PROJECT_STATUS_SUCCESS: 'CHANGE_PROJECT_STATUS_SUCCESS',
    CHANGE_PROJECT_STATUS_ERROR: 'CHANGE_PROJECT_STATUS_ERROR',

    //Create Project
    CREATE_PROJECT_BEGIN: 'CREATE_PROJECT_BEGIN',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_ERROR: 'CREATE_PROJECT_ERROR',

    //Get Project Info
    GET_PROJECTS_INFO_SUCCESS: 'GET_PROJECTS_INFO_SUCCESS',
    GET_PROJECTS_INFO_BEGIN: 'GET_PROJECTS_INFO_BEGIN',
    GET_PROJECTS_INFO_FAILURE: 'GET_PROJECTS_INFO_FAILURE',

    //Change cloud provider
    CHANGE_CLOUD_SUCCESS: 'CHANGE_CLOUD_SUCCESS',
    CHANGE_CLOUD_BEGIN: 'CHANGE_CLOUD_BEGIN',
    CHANGE_CLOUD_ERROR: 'CHANGE_CLOUD_ERROR',

    //Company
    ADD_COMPANY_BEGIN: 'ADD_COMPANY_BEGIN',
    ADD_COMPANY_ERROR: 'ADD_COMPANY_ERROR',
    ADD_COMPANY_SUCCESS: 'ADD_COMPANY_SUCCESS',
    DELETE_COMPANY_BEGIN: 'DELETE_COMPANY_BEGIN',
    DELETE_COMPANY_ERROR: 'DELETE_COMPANY_ERROR',
    DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
    CHANGE_COMPANY_NAME_BEGIN: 'CHANGE_COMPANY_NAME_BEGIN',
    CHANGE_COMPANY_NAME_SUCCESS: 'CHANGE_COMPANY_NAME_SUCCESS',
    CHANGE_COMPANY_NAME_ERROR: 'CHANGE_COMPANY_NAME_ERROR',

    //Templates
    GET_TEMPLATES_BEGIN: "GET_TEMPLATES_BEGIN",
    GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
    GET_TEMPLATES_FAILURE: "GET_TEMPLATES_FAILURE",

    CHANGE_BACKGRAUND: 'CHANGE_BACKGRAUND',

    //Project Settings
    GET_PROJECS_SETTINGS_ERROR: 'GET_PROJECS_SETTINGS_ERROR',
    GET_PROJECS_SETTINGS_INFO_BEGIN: 'GET_PROJECS_SETTINGS_INFO_BEGIN',
    GET_PROJECS_SETTINGS_INFO_SUCCESS: 'GET_PROJECS_SETTINGS_INFO_SUCCESS',
    GET_PROJECS_SETTINGS_INFO_ERROR: 'GET_PROJECS_SETTINGS_INFO_ERROR',
    STORE_PROJECT_GENERAL_SETTING: 'STORE_PROJECT_GENERAL_SETTING',
    GET_ALL_SYSTEM_SETTINGS_SUCCESS: 'GET_ALL_SYSTEM_SETTINGS_SUCCESS',
    GET_ALL_SYSTEM_SETTINGS_ERROR: 'GET_ALL_SYSTEM_SETTINGS_ERROR',
    INIT_OVERVIEW_ON_LOAD: 'INIT_OVERVIEW_ON_LOAD',

    // Languages Settings
    GET_PROJECS_LANGUAGES_ERROR: 'GET_PROJECS_LANGUAGES_ERROR',
    GET_PROJECS_LANGUAGES_SUCCESS: 'GET_PROJECS_LANGUAGES_SUCCESS',
    SET_PROJECTS_LANGUAGES_ERROR: 'SET_PROJECTS_LANGUAGES_ERROR',
    SET_PROJECTS_LANGUAGES_SUCCESS: 'SET_PROJECTS_LANGUAGES_SUCCESS',
    GET_LANGUAGES_BEGIN: 'GET_LANGUAGES_BEGIN',
    GET_ALL_LANGUAGES_ERROR: "GET_ALL_LANGUAGES_ERROR",
    GET_ALL_LANGUAGES_SUCCESS: "GET_ALL_LANGUAGES_SUCCESS",
    SET_LANGUAGES_BEGIN: 'SET_LANGUAGES_BEGIN',

    // Captcha Settings
    GET_PROJECT_CAPTCHA_PROVIDERS_ERROR: 'GET_PROJECS_CAPTCHA_PROVIDERS_ERROR',
    GET_PROJECT_CAPTCHA_PROVIDERS_SUCCESS: 'GET_PROJECT_CAPTCHA_PROVIDERS_SUCCESS',
    SET_PROJECTS_CAPTCHA_ERROR: 'SET_PROJECTS_CAPTCHA_ERROR',
    SET_PROJECTS_CAPTCHA_SUCCESS: 'SET_PROJECTS_CAPTCHA_SUCCESS',
    GET_ALL_CAPTCHA_PROVIDERS_ERROR: "GET_ALL_CAPTCHA_PROVIDERS_ERROR",
    GET_PROJECS_ALL_CAPTCHA_PROVIDERS_SUCCESS: "GET_PROJECS_ALL_CAPTCHA_PROVIDERS_SUCCESS",
    GET_CAPTCHA_BEGIN: 'GET_CAPTCHA_BEGIN',
    SET_CAPTCHA_BEGIN: 'SET_CAPTCHA_BEGIN',

    // Payments Settings
    GET_PAYMENTS_BEGIN: 'GET_PAYMENTS_BEGIN',
    GET_PAYMENTS_ERROR: 'GET_PAYMENTS_ERROR',
    GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
    SHOW_ON_WEB_BEGIN: 'SHOW_ON_WEB_BEGIN',
    SHOW_ON_WEB_ERROR: 'SHOW_ON_WEB_ERROR',
    SHOW_ON_WEB_SUCCESS: 'SHOW_ON_WEB_SUCCESS',

    // Currencies Settings
    GET_PROJECS_CURRENCIES_ERROR: 'GET_PROJECS_CURRENCIES_ERROR',
    GET_PROJECT_CURRENCIES_SUCCESS: 'GET_PROJECT_CURRENCIES_SUCCESS',
    GET_ALL_CURRENCIES_SUCCESS: "GET_ALL_CURRENCIES_SUCCESS",
    GET_ALL_CURRENCIES_ERROR: "GET_ALL_CURRENCIES_ERROR",
    SET_PROJECTS_CURRENCIES_ERROR: 'SET_PROJECTS_CURRENCIES_ERROR',
    SET_PROJECTS_CURRENCIES_SUCCESS: 'SET_PROJECTS_CURRENCIES_SUCCESS',
    GET_CURRENCIES_BEGIN: 'GET_CURRENCIES_BEGIN',
    SET_CURRENCIES_BEGIN: 'SET_CURRENCIES_BEGIN',

    // Logos Settings
    GET_LOGOS_ERROR: 'GET_LOGOS_ERROR',
    GET_LOGOS_SUCCESS: 'GET_LOGOS_SUCCESS',
    SET_LOGOS_ERROR: 'SET_LOGOS_ERROR',
    SET_LOGOS_SUCCESS: 'SET_LOGOS_SUCCESS',
    GET_LOGOS_BEGIN: 'GET_LOGOS_BEGIN',
    SET_LOGOS_BEGIN: 'SET_LOGOS_BEGIN',
    UPLOAD_LOGOS_BEGIN: 'UPLOAD_LOGOS_BEGIN',
    UPLOAD_LOGOS_ERROR: 'UPLOAD_LOGOS_ERROR',
    UPLOAD_LOGOS_SUCCESS: 'UPLOAD_LOGOS_SUCCESS',
    GET_LOGO_SETTINGS_SUCCESS: 'GET_LOGO_SETTINGS_SUCCESS',
    CHANGE_LOGO_SETTINGS_SUCCESS: 'CHANGE_LOGO_SETTINGS_SUCCESS',
    GET_LOGOS_SETTINGS_BEGIN: 'GET_LOGOS_SETTINGS_BEGIN',
    GET_LOGOS_SETTINGS_ERROR: 'GET_LOGOS_SETTINGS_ERROR',

    // Add Ons Settings
    GET_ADD_ONS_SUCCESS: 'GET_ADD_ONS_SUCCESS',
    GET_ADD_ONS_ERROR: 'GET_ADD_ONS_ERROR',
    GET_ADD_ONS_BEGIN: 'GET_ADD_ONS_BEGIN',
    SET_ADD_ON_BEGIN: 'SET_ADD_ON_BEGIN',
    SET_ADD_ON_ERROR: 'SET_ADD_ON_ERROR',
    SET_ADD_ON_SUCCESS: 'SET_ADD_ON_SUCCESS',

    // General Settings
    GET_GENERAL_SETTINGS_ERROR: 'GET_GENERAL_SETTINGS_ERROR',
    GET_GENERAL_SETTINGS_SUCCESS: 'GET_GENERAL_SETTINGS_SUCCESS',
    SET_GENERAL_SETTINGS_ERROR: 'SET_GENERAL_SETTINGS_ERROR',
    SET_GENERAL_SETTINGS_SUCCESS: 'SET_GENERAL_SETTINGS_SUCCESS',
    GET_GENERAL_SETTINGS_BEGIN: 'GET_GENERAL_SETTINGS_BEGIN',
    SET_GENERAL_SETTINGS_BEGIN: 'SET_GENERAL_SETTINGS_BEGIN',

    GET_CLIENT_MIN_AGE_BEGIN: 'GET_CLIENT_MIN_AGE_BEGIN',
    GET_CLIENT_MIN_AGE_ERROR: 'GET_CLIENT_MIN_AGE_ERROR',
    GET_CLIENT_MIN_AGE_SUCCESS: 'GET_CLIENT_MIN_AGE_SUCCESS',
    SET_CLIENT_MIN_AGE_BEGIN: 'SET_CLIENT_MIN_AGE_BEGIN',
    SET_CLIENT_MIN_AGE_ERROR: 'SET_CLIENT_MIN_AGE_ERROR',
    SET_CLIENT_MIN_AGE_SUCCESS: 'SET_CLIENT_MIN_AGE_SUCCESS',

    GET_REFERRAL_FRIEND_BEGIN: 'GET_REFERRAL_FRIEND_BEGIN',
    GET_REFERRAL_FRIEND_ERROR: 'GET_REFERRAL_FRIEND_ERROR',
    GET_REFERRAL_FRIEND_SUCCESS: 'GET_REFERRAL_FRIEND_SUCCESS',
    SET_REFERRAL_FRIEND_BEGIN: 'SET_REFERRAL_FRIEND_BEGIN',
    SET_REFERRAL_FRIEND_ERROR: 'SET_REFERRAL_FRIEND_ERROR',
    SET_REFERRAL_FRIEND_SUCCESS: 'SET_REFERRAL_FRIEND_SUCCESS',


    GET_MARKETS_BEGIN: 'GET_MARKETS_BEGIN',
    GET_MARKETS_ERROR: 'GET_MARKETS_ERROR',
    GET_MARKETS_SUCCESS: 'GET_MARKETS_SUCCESS',
    SET_MARKET_BEGIN: 'SET_MARKET_BEGIN',
    SET_MARKET_ERROR: 'SET_MARKET_ERROR',
    SET_MARKET_SUCCESS: 'SET_MARKET_SUCCESS',

    // Domain Settings
    GET_ALL_DOMAINS_BEGIN: 'GET_ALL_DOMAINS_BEGIN',
    GET_ALL_DOMAINS_ERROR: 'GET_ALL_DOMAINS_ERROR',
    GET_ALL_DOMAINS_SUCCESS: 'GET_ALL_DOMAINS_SUCCESS',
    GET_DOMAIN_SETTINGS_ERROR: 'GET_DOMAIN_SETTINGS_ERROR',
    GET_DOMAIN_SETTINGS_SUCCESS: 'GET_DOMAIN_SETTINGS_SUCCESS',
    ADD_DOMAIN_ERROR: 'ADD_DOMAIN_ERROR',
    ADD_DOMAIN_SUCCESS: 'ADD_DOMAIN_SUCCESS',
    ADD_DOMAIN_BEGIN: 'ADD_DOMAIN_BEGIN',
    ALL_DOMAINS_ON_LOAD: 'ALL_DOMAINS_ON_LOAD',
    REMOVE_DOMAIN_ERROR: 'REMOVE_DOMAIN_ERROR',
    REMOVE_DOMAIN_BEGIN: 'REMOVE_DOMAIN_BEGIN',
    REMOVE_DOMAIN_SUCCESS: 'REMOVE_DOMAIN_SUCCESS',

    DOMAIN_ACTIVATION_CHECK_BEGIN: 'DOMAIN_ACTIVATION_CHECK_BEGIN',
    DOMAIN_ACTIVATION_CHECK_ERROR: 'DOMAIN_ACTIVATION_CHECK_ERROR',
    DOMAIN_ACTIVATION_CHECK_SUCCESS: 'DOMAIN_ACTIVATION_CHECK_SUCCESS',
    DOMAIN_PROCESSING_BEGIN: 'DOMAIN_PROCESSING_BEGIN',
    DOMAIN_PROCESSING_END: 'DOMAIN_PROCESSING_END',
    CLEAR_DOMAIN_CACHE_ERROR: 'CLEAR_DOMAIN_CACHE_ERROR',
    CLEAR_DOMAIN_CACHE_SUCCESS: 'CLEAR_DOMAIN_CACHE_SUCCESS',
    CHANGE_DOMAIN_BEGIN: 'CHANGE_DOMAIN_BEGIN',
    CHANGE_DOMAIN_SUCCESS: 'CHANGE_DOMAIN_SUCCESS',
    CHANGE_DOMAIN_ERROR: 'CHANGE_DOMAIN_ERROR',
    GET_DOMAIN_ERRORS_BEGIN: 'GET_DOMAIN_ERRORS_BEGIN',
    GET_DOMAIN_ERRORS_SUCCESS: 'GET_DOMAIN_ERRORS_SUCCESS',
    GET_DOMAIN_ERRORS_ERROR: 'GET_DOMAIN_ERRORS_ERROR',

    // 3rd Party Integrations
    GET_EXTERNAL_SERVICES_BEGIN: 'GET_EXTERNAL_SERVICES_BEGIN',
    GET_EXTERNAL_SERVICES_ERROR: 'GET_EXTERNAL_SERVICES_ERROR',
    GET_EXTERNAL_SERVICES_SUCCESS: 'GET_EXTERNAL_SERVICES_SUCCESS',
    ADD_CUSTOM_EXTERNAL_SERVICE_BEGIN: 'ADD_CUSTOM_EXTERNAL_SERVICE_BEGIN',
    ADD_CUSTOM_EXTERNAL_SERVICE_ERROR: 'ADD_CUSTOM_EXTERNAL_SERVICE_ERROR',
    ADD_CUSTOM_EXTERNAL_SERVICE_SUCCESS: 'ADD_CUSTOM_EXTERNAL_SERVICE_SUCCESS',
    ADD_STANDART_EXTERNAL_SERVICE_BEGIN: 'ADD_STANDART_EXTERNAL_SERVICE_BEGIN',
    ADD_STANDART_EXTERNAL_SERVICE_ERROR: 'ADD_STANDART_EXTERNAL_SERVICE_ERROR',
    ADD_STANDART_EXTERNAL_SERVICE_SUCCESS: 'ADD_STANDART_EXTERNAL_SERVICE_SUCCESS',
    EDIT_CUSTOM_EXTERNAL_SERVICE_BEGIN: 'EDIT_CUSTOM_EXTERNAL_SERVICE_BEGIN',
    EDIT_CUSTOM_EXTERNAL_SERVICE_ERROR: 'EDIT_CUSTOM_EXTERNAL_SERVICE_ERROR',
    EDIT_CUSTOM_EXTERNAL_SERVICE_SUCCESS: 'EDIT_CUSTOM_EXTERNAL_SERVICE_SUCCESS',
    EDIT_STANDART_EXTERNAL_SERVICE_BEGIN: 'EDIT_STANDART_EXTERNAL_SERVICE_BEGIN',
    EDIT_STANDART_EXTERNAL_SERVICE_ERROR: 'EDIT_STANDART_EXTERNAL_SERVICE_ERROR',
    EDIT_STANDART_EXTERNAL_SERVICE_SUCCESS: 'EDIT_STANDART_EXTERNAL_SERVICE_SUCCESS',
    EDIT_EXTERNAL_SERVICE_STATE_BEGIN: 'EDIT_EXTERNAL_SERVICE_STATE_BEGIN',
    EDIT_EXTERNAL_SERVICE_STATE_ERROR: 'EDIT_EXTERNAL_SERVICE_STATE_ERROR',
    EDIT_EXTERNAL_SERVICE_STATE_SUCCESS: 'EDIT_EXTERNAL_SERVICE_STATE_SUCCESS',
    GET_ALL_EXTERNAL_SERVICES_BEGIN: 'GET_ALL_EXTERNAL_SERVICES_BEGIN',
    GET_ALL_EXTERNAL_SERVICES_ERROR: 'GET_ALL_EXTERNAL_SERVICES_ERROR',
    GET_ALL_EXTERNAL_SERVICES_SUCCESS: 'GET_ALL_EXTERNAL_SERVICES_SUCCESS',
    GET_STANDART_EXTERNAL_SERVICE_BEGIN: 'GET_STANDART_EXTERNAL_SERVICE_BEGIN',
    GET_STANDART_EXTERNAL_SERVICE_ERROR: 'GET_STANDART_EXTERNAL_SERVICE_ERROR',
    GET_STANDART_EXTERNAL_SERVICE_SUCCESS: 'GET_STANDART_EXTERNAL_SERVICE_SUCCESS',
    GET_CUSTOM_EXTERNAL_SERVICE_BEGIN: 'GET_CUSTOM_EXTERNAL_SERVICE_BEGIN',
    GET_CUSTOM_EXTERNAL_SERVICE_ERROR: 'GET_CUSTOM_EXTERNAL_SERVICE_ERROR',
    GET_CUSTOM_EXTERNAL_SERVICE_SUCCESS: 'GET_CUSTOM_EXTERNAL_SERVICE_SUCCESS',
    DELETE_EXTERNAL_SERVICE_BEGIN: 'DELETE_EXTERNAL_SERVICE_BEGIN',
    DELETE_EXTERNAL_SERVICE_ERROR: 'DELETE_EXTERNAL_SERVICE_ERROR',
    DELETE_EXTERNAL_SERVICE_SUCCESS: 'DELETE_EXTERNAL_SERVICE_SUCCESS',
    CLEAR_SERVICE_DETAILS: 'CLEAR_SERVICE_DETAILS',

    // BANNERS SETTINGS

    GET_BANNERS_BEGIN: 'GET_BANNERS_BEGIN',
    GET_BANNERS_ERROR: 'GET_BANNERS_ERROR',
    GET_BANNERS_SUCCESS: 'GET_BANNERS_SUCCESS',
    ADD_BANNER_BEGIN: 'ADD_BANNER_BEGIN',
    ADD_BANNER_ERROR: 'ADD_BANNER_ERROR',
    ADD_BANNER_SUCCESS: 'ADD_BANNER_SUCCESS',
    EDIT_BANNER_SUCCESS: 'EDIT_BANNER_SUCCESS',
    DELETE_BANNER_BEGIN: 'DELETE_BANNER_BEGIN',
    DELETE_BANNER_ERROR: 'DELETE_BANNER_ERROR',
    DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
    SET_SECTION_BEGIN: 'SET_SECTION_BEGIN',
    SET_SECTION_ERROR: 'SET_SECTION_ERROR',
    SET_SECTION_SUCCESS: 'SET_SECTION_SUCCESS',
    MOVE_BANNER_BEGIN: 'MOVE_BANNER_BEGIN',
    MOVE_BANNER_ERROR: 'MOVE_BANNER_ERROR',
    MOVE_BANNER_SUCCESS: 'MOVE_BANNER_SUCCESS',
    CHECK_BANNER_NAME_BEGIN: 'CHECK_BANNER_NAME_BEGIN',
    CHECK_BANNER_NAME_ERROR: 'CHECK_BANNER_NAME_ERROR',
    CHECK_BANNER_NAME_SUCCESS: 'CHECK_BANNER_NAME_SUCCESS',
    CHANGE_BANNER_SECTION_VISIBILITY_BEGIN: 'CHANGE_BANNER_SECTION_VISIBILITY_BEGIN',
    CHANGE_BANNER_SECTION_VISIBILITY_SUCCESS: 'CHANGE_BANNER_SECTION_VISIBILITY_SUCCESS',
    CHANGE_BANNER_SECTION_VISIBILITY_ERROR: 'CHANGE_BANNER_SECTION_VISIBILITY_ERROR',
    GET_BANNER_CATEGORIES_BY_SPORT_STATUS_BEGIN: 'GET_BANNER_CATEGORIES_BY_SPORT_STATUS_BEGIN',
    GET_BANNER_CATEGORIES_BY_SPORT_STATUS_ERROR: 'GET_BANNER_CATEGORIES_BY_SPORT_STATUS_ERROR',
    GET_BANNER_CATEGORIES_BY_SPORT_STATUS_SUCCESS: 'GET_BANNER_CATEGORIES_BY_SPORT_STATUS_SUCCESS',

    //CUSTOM CODES

    GET_CUSTOM_CODES_BEGIN: 'GET_CUSTOM_CODES_BEGIN',
    GET_CUSTOM_CODES_ERROR: 'GET_CUSTOM_CODES_ERROR',
    GET_CUSTOM_CODES_SUCCESS: 'GET_CUSTOM_CODES_SUCCESS',

    GET_CUSTOM_CODE_BEGIN: 'GET_CUSTOM_CODE_BEGIN',
    GET_CUSTOM_CODE_ERROR: 'GET_CUSTOM_CODE_ERROR',
    GET_CUSTOM_CODE_SUCCESS: 'GET_CUSTOM_CODE_SUCCESS',

    CLEAR_CODE_DETAILS: 'CLEAR_CODE_DETAILS',

    ADD_CUSTOM_CODE_BEGIN: 'ADD_CUSTOM_CODE_BEGIN',
    ADD_CUSTOM_CODE_ERROR: 'ADD_CUSTOM_CODE_ERROR',
    ADD_CUSTOM_CODE_SUCCESS: 'ADD_CUSTOM_CODE_SUCCESS',

    EDIT_CUSTOM_CODE_BEGIN: 'EDIT_CUSTOM_CODE_BEGIN',
    EDIT_CUSTOM_CODE_ERROR: 'EDIT_CUSTOM_CODE_ERROR',
    EDIT_CUSTOM_CODE_SUCCESS: 'EDIT_CUSTOM_CODE_SUCCESS',

    CHANGE_CUSTOM_CODE_STATE_BEGIN: 'CHANGE_CUSTOM_CODE_STATE_BEGIN',
    CHANGE_CUSTOM_CODE_STATE_ERROR: 'CHANGE_CUSTOM_CODE_STATE_ERROR',
    CHANGE_CUSTOM_CODE_STATE_SUCCESS: 'CHANGE_CUSTOM_CODE_STATE_SUCCESS',

    DELETE_CUSTOM_CODE_BEGIN: 'DELETE_CUSTOM_CODE_BEGIN',
    DELETE_CUSTOM_CODE_ERROR: 'DELETE_CUSTOM_CODE_ERROR',
    DELETE_CUSTOM_CODE_SUCCESS: 'DELETE_CUSTOM_CODE_SUCCESS',




    //New project details
    SET_PROJECTS_STEP_PROGRESS: 'SET_PROJECTS_STEP_PROGRESS',
    SET_NEW_PROJECT_NAME: 'SET_NEW_PROJECT_NAME',
    SET_NEW_PROJECT_TEMPLATE_ID: 'SET_NEW_PROJECT_TEMPLATE_ID',


    //UI Customization
    GET_CONTROLS_BEGIN: 'GET_CONTROLS_BEGIN',
    GET_CONTROLS_ERROR: 'GET_CONTROLS_ERROR',
    GET_CONTROLS_SUCCESS: 'GET_CONTROLS_SUCCESS',
    SET_CONTROLS_BEGIN: 'SET_CONTROLS_BEGIN',
    SET_CONTROLS_ERROR: 'SET_CONTROLS_ERROR',
    SET_CONTROLS_SUCCESS: 'SET_CONTROLS_SUCCESS',
    SET_BRANDING_INITIAL_SETTINGS: 'SET_BRANDING_INITIAL_SETTINGS',

    DELETE_UI_CONTROL_BEGIN: 'DELETE_CONTROL_BEGIN',
    DELETE_UI_CONTROL_ERROR: 'DELETE_CONTROL_ERROR',
    DELETE_UI_CONTROL_SUCCESS: 'DELETE_CONTROL_SUCCESS',

    GET_SECTIONS_BEGIN: 'GET_SECTIONS_BEGIN',
    GET_SECTIONS_ERROR: 'GET_SECTIONS_ERROR',
    GET_SECTIONS_SUCCESS: 'GET_SECTIONS_SUCCESS',

    GET_GRID_LAYOUT_BEGIN: 'GET_GRID_LAYOUT_BEGIN',
    GET_GRID_LAYOUT_ERROR: 'GET_GRID_LAYOUT_ERROR',
    GET_GRID_LAYOUT_SUCCESS: 'GET_GRID_LAYOUT_SUCCESS',

    UPDATE_BANNER_GRID_LAYOUT_BEGIN: 'UPDATE_BANNER_GRID_LAYOUT_BEGIN',
    UPDATE_BANNER_GRID_LAYOUT_ERROR: 'UPDATE_BANNER_GRID_LAYOUT_ERROR',
    UPDATE_BANNER_GRID_LAYOUT_SUCCESS: 'UPDATE_BANNER_GRID_LAYOUT_SUCCESS',

    CHANGE_CONTROL_VISIBILITY_BEGIN: 'CHANGE_CONTROL_VISIBILITY_BEGIN',
    CHANGE_CONTROL_VISIBILITY_ERROR: 'CHANGE_CONTROL_VISIBILITY_ERROR',
    CHANGE_CONTROL_VISIBILITY_SUCCESS: 'CHANGE_CONTROL_VISIBILITY_SUCCESS',
    CHANGE_BRANDING_SETTINGS_STATE: 'CHANGE_BRANDING_SETTINGS_STATE',

    RULES_BUTTONS_VISIBILITY_BEGIN: 'RULES_BUTTONS_VISIBILITY_BEGIN',
    RULES_BUTTONS_VISIBILITY_ERROR: 'RULES_BUTTONS_VISIBILITY_ERROR',
    RULES_BUTTONS_VISIBILITY_SUCCESS: 'RULES_BUTTONS_VISIBILITY_SUCCESS',

    GET_NOT_CLASSIFIED_ITEMS_BEGIN: 'GET_NOT_CLASSIFIED_ITEMS_BEGIN',
    GET_NOT_CLASSIFIED_ITEMS_SUCCESS: 'GET_NOT_CLASSIFIED_ITEMS_SUCCESS',
    GET_NOT_CLASSIFIED_ITEMS_ERROR: 'GET_NOT_CLASSIFIED_ITEMS_ERROR',

    EDIT_UI_CONTROL_ERROR: 'EDIT_UI_CONTROL_ERROR',
    EDIT_UI_CONTROL_SUCCESS: 'EDIT_UI_CONTROL_SUCCESS',
    EDIT_UI_CONTROL_BEGIN: 'EDIT_UI_CONTROL_BEGIN',

    CHANGE_UI_CONTROL_VISIBILITY_BEGIN: 'CHANGE_UI_CONTROL_VISIBILITY_BEGIN',
    CHANGE_UI_CONTROL_VISIBILITY_ERROR: 'CHANGE_UI_CONTROL_VISIBILITY_ERROR',
    CHANGE_UI_CONTROL_VISIBILITY_SUCCESS: 'CHANGE_UI_CONTROL_VISIBILITY_SUCCESS',

    GET_ICON_SET_BEGIN: 'GET_ICON_SET_BEGIN',
    GET_ICON_SET_ERROR: 'GET_ICON_SET_ERROR',
    GET_ICON_SET_SUCCESS: 'GET_ICON_SET_SUCCESS',

    GET_ICONS_BEGIN: 'GET_ICONS_BEGIN',
    GET_ICONS_ERROR: 'GET_ICONS_ERROR',
    GET_ICONS_SUCCESS: 'GET_ICONS_SUCCESS',

    SET_ICON_SET_BEGIN: 'SET_ICON_SET_BEGIN',
    SET_ICON_SET_ERROR: 'SET_ICON_SET_ERROR',
    SET_ICON_SET_SUCCESS: 'SET_ICON_SET_SUCCESS',

    GET_LANGUAGE_CONTROLS_BEGIN: 'GET_LANGUAGE_CONTROLS_BEGIN',
    GET_LANGUAGE_CONTROLS_ERROR: 'GET_LANGUAGE_CONTROLS_ERROR',
    GET_LANGUAGE_CONTROLS_SUCCESS: 'GET_LANGUAGE_CONTROLS_SUCCESS',

    EDIT_LANGUAGE_CONTROL_BEGIN: 'EDIT_LANGUAGE_CONTROL_BEGIN',
    EDIT_LANGUAGE_CONTROL_ERROR: 'EDIT_LANGUAGE_CONTROL_ERROR',
    EDIT_LANGUAGE_CONTROL_SUCCESS: 'EDIT_LANGUAGE_CONTROL_SUCCESS',

    GET_POPUPS_COLOR_MODE_BEGIN: 'GET_POPUPS_COLOR_MODE_BEGIN',
    GET_POPUPS_COLOR_MODE_ERROR: 'GET_POPUPS_COLOR_MODE_ERROR',
    GET_POPUPS_COLOR_MODE_SUCCESS: 'GET_POPUPS_COLOR_MODE_SUCCESS',
    SET_COLOR_MODE_SETTINGS: 'SET_COLOR_MODE_SETTINGS',
    CHANGE_WEBSITE_COLOR_MODE: 'CHANGE_WEBSITE_COLOR_MODE',

    GET_IMAGE_BLOCKS_BEGIN: 'GET_IMAGE_BLOCKS_BEGIN',
    GET_IMAGE_BLOCKS_SUCCESS: 'GET_IMAGE_BLOCKS_SUCCESS',
    GET_IMAGE_BLOCKS_ERROR: 'GET_IMAGE_BLOCKS_ERROR',
    ADD_IMAGE_BLOCKS_BEGIN: 'ADD_IMAGE_BLOCKS_BEGIN',
    ADD_IMAGE_BLOCKS_SUCCESS: 'ADD_IMAGE_BLOCKS_SUCCESS',
    ADD_IMAGE_BLOCKS_ERROR: 'ADD_IMAGE_BLOCKS_ERROR',
    CHANGE_IMAGE_BLOCKS_VISIBILITY_BEGIN: 'CHANGE_IMAGE_BLOCKS_VISIBILITY_BEGIN',
    CHANGE_IMAGE_BLOCKS_VISIBILITY_SUCCESS: 'CHANGE_IMAGE_BLOCKS_VISIBILITY_SUCCESS',
    CHANGE_IMAGE_BLOCKS_VISIBILITY_ERROR: 'CHANGE_IMAGE_BLOCKS_VISIBILITY_ERROR',
    MOVE_IMAGE_BLOCKS_VISIBILITY_BEGIN: 'MOVE_IMAGE_BLOCKS_VISIBILITY_BEGIN',
    MOVE_IMAGE_BLOCKS_VISIBILITY_SUCCESS: 'MOVE_IMAGE_BLOCKS_VISIBILITY_SUCCESS',
    MOVE_IMAGE_BLOCKS_VISIBILITY_ERROR: 'MOVE_IMAGE_BLOCKS_VISIBILITY_ERROR',
    EDIT_IMAGE_BLOCK_BEGIN: 'EDIT_IMAGE_BLOCK_BEGIN',
    EDIT_IMAGE_BLOCK_SUCCESS: 'EDIT_IMAGE_BLOCK_SUCCESS',
    EDIT_IMAGE_BLOCK_ERROR: 'EDIT_IMAGE_BLOCK_ERROR',
    DELETE_IMAGE_BLOCK_BEGIN: 'DELETE_IMAGE_BLOCK_BEGIN',
    DELETE_IMAGE_BLOCK_SUCCESS: 'DELETE_IMAGE_BLOCK_SUCCESS',
    DELETE_IMAGE_BLOCK_ERROR: 'DELETE_IMAGE_BLOCK_ERROR',

    GET_APP_ADVISOR_BEGIN:'GET_APP_ADVISOR_BEGIN',
    GET_APP_ADVISOR_SUCCESS:'GET_APP_ADVISOR_SUCCESS',
    GET_APP_ADVISOR_ERROR:'GET_APP_ADVISOR_ERROR',
    SET_APP_ADVISOR_BEGIN:'SET_APP_ADVISOR_BEGIN',
    SET_APP_ADVISOR_SUCCESS:'SET_APP_ADVISOR_SUCCESS',
    SET_APP_ADVISOR_ERROR:'SET_APP_ADVISOR_ERROR',

    GET_MOBILE_APPS_BEGIN:'GET_MOBILE_APPS_BEGIN',
    GET_MOBILE_APPS_SUCCESS:'GET_MOBILE_APPS_SUCCESS',
    GET_MOBILE_APPS_ERROR:'GET_MOBILE_APPS_ERROR',
    SET_MOBILE_APPS_BEGIN:'SET_MOBILE_APPS_BEGIN',
    SET_MOBILE_APPS_SUCCESS:'SET_MOBILE_APPS_SUCCESS',
    SET_MOBILE_APPS_ERROR:'SET_MOBILE_APPS_ERROR',
    SET_MOBILE_APPS_DEVICE_TYPE_BEGIN:'SET_MOBILE_APPS_DEVICE_TYPE_BEGIN',
    SET_MOBILE_APPS_DEVICE_TYPE_SUCCESS:'SET_MOBILE_APPS_DEVICE_TYPE_SUCCESS',
    SET_MOBILE_APPS_DEVICE_TYPE_ERROR:'SET_MOBILE_APPS_DEVICE_TYPE_ERROR',

    GET_STORY_BLOCKS_BEGIN: 'GET_STORY_BLOCKS_BEGIN',
    GET_STORY_BLOCKS_SUCCESS: 'GET_STORY_BLOCKS_SUCCESS',
    GET_STORY_BLOCKS_ERROR: 'GET_STORY_BLOCKS_ERROR',
    ADD_STORY_BLOCKS_BEGIN: 'ADD_STORY_BLOCKS_BEGIN',
    ADD_STORY_BLOCKS_SUCCESS: 'ADD_STORY_BLOCKS_SUCCESS',
    ADD_STORY_BLOCKS_ERROR: 'ADD_STORY_BLOCKS_ERROR',
    CHANGE_STORY_BLOCKS_SETTINGS_BEGIN: 'CHANGE_STORY_BLOCKS_SETTINGS_BEGIN',
    CHANGE_STORY_BLOCKS_SETTINGS_SUCCESS: 'CHANGE_STORY_BLOCKS_SETTINGS_SUCCESS',
    CHANGE_STORY_BLOCKS_SETTINGS_ERROR: 'CHANGE_STORY_BLOCKS_SETTINGS_ERROR',
    CHANGE_STORY_IMAGE_SETTINGS_BEGIN: 'CHANGE_STORY_IMAGE_SETTINGS_BEGIN',
    CHANGE_STORY_IMAGE_SETTINGS_SUCCESS: 'CHANGE_STORY_IMAGE_SETTINGS_SUCCESS',
    CHANGE_STORY_BLOCKS_VISIBILITY_SETTINGS_SUCCESS: 'CHANGE_STORY_BLOCKS_VISIBILITY_SETTINGS_SUCCESS',
    CHANGE_STORY_IMAGE_SETTINGS_ERROR: 'CHANGE_STORY_IMAGE_SETTINGS_ERROR',
    GET_STORY_SECTIONS_BEGIN: 'GET_STORY_SECTIONS_BEGIN',
    GET_STORY_GET_SECTIONS_ERROR: 'GET_STORY_GET_SECTIONS_ERROR',
    GET_STORY_SECTIONS_SUCCESS: 'GET_STORY_SECTIONS_SUCCESS',
    GET_STORY_BLOCKS_SETTINGS_BEGIN: 'GET_STORY_BLOCKS_SETTINGS_BEGIN',
    GET_STORY_BLOCKS_SETTINGS_SUCCESS: 'GET_STORY_BLOCKS_SETTINGS_SUCCESS',
    GET_STORY_BLOCKS_SETTINGS_ERROR: 'GET_STORY_BLOCKS_SETTINGS_ERROR',
    MOVE_STORY_BLOCKS_VISIBILITY_BEGIN: 'MOVE_STORY_BLOCKS_VISIBILITY_BEGIN',
    MOVE_STORY_BLOCKS_VISIBILITY_SUCCESS: 'MOVE_STORY_BLOCKS_VISIBILITY_SUCCESS',
    MOVE_STORY_BLOCKS_VISIBILITY_ERROR: 'MOVE_STORY_BLOCKS_VISIBILITY_ERROR',
    EDIT_STORY_BLOCK_BEGIN: 'EDIT_STORY_BLOCK_BEGIN',
    EDIT_STORY_BLOCK_SUCCESS: 'EDIT_STORY_BLOCK_SUCCESS',
    EDIT_STORY_BLOCK_ERROR: 'EDIT_STORY_BLOCK_ERROR',
    DELETE_STORY_BLOCK_BEGIN: 'DELETE_STORY_BLOCK_BEGIN',
    DELETE_STORY_BLOCK_SUCCESS: 'DELETE_STORY_BLOCK_SUCCESS',
    DELETE_STORY_BLOCK_ERROR: 'DELETE_STORY_BLOCK_ERROR',

    GET_ACCESS_BUTTONS_BEGIN:'GET_ACCESS_BUTTONS_BEGIN',
    GET_ACCESS_BUTTONS_ERROR:'GET_ACCESS_BUTTONS_ERROR',
    GET_ACCESS_BUTTONS_SUCCESS:'GET_ACCESS_BUTTONS_SUCCESS',
    EDIT_ACCESS_BUTTONS_BEGIN:'EDIT_ACCESS_BUTTONS_BEGIN',
    EDIT_ACCESS_BUTTONS_ERROR:'EDIT_ACCESS_BUTTONS_ERROR',
    EDIT_ACCESS_BUTTONS_SUCCESS:'EDIT_ACCESS_BUTTONS_SUCCESS',

    GET_MOBILE_APP_DATA_BEGIN:'GET_MOBILE_APP_DATA_BEGIN',
    GET_MOBILE_APP_DATA_ERROR:'GET_MOBILE_APP_DATA_ERROR',
    GET_MOBILE_APP_DATA_SUCCESS:'GET_MOBILE_APP_DATA_SUCCESS',
    EDIT_MOBILE_APP_DATA_BEGIN:'EDIT_MOBILE_APP_DATA_BEGIN',
    EDIT_MOBILE_APP_DATA_ERROR:'EDIT_MOBILE_APP_DATA_ERROR',
    EDIT_MOBILE_APP_DATA_SUCCESS:'EDIT_MOBILE_APP_DATA_SUCCESS',
    
    //Menu
    ADD_MENU_ITEM_BEGIN: 'ADD_MENU_ITEM_BEGIN',
    ADD_MENU_ITEM_ERROR: 'ADD_MENU_ITEM_ERROR',
    ADD_MENU_ITEM_SUCCESS: 'ADD_MENU_ITEM_SUCCESS',
    EDIT_MENU_ITEM_BEGIN: 'EDIT_MENU_ITEM_BEGIN',
    EDIT_MENU_ITEM_ERROR: 'EDIT_MENU_ITEM_ERROR',
    EDIT_MENU_ITEM_SUCCESS: 'EDIT_MENU_ITEM_SUCCESS',

    MOVE_MENU_ITEM_BEGIN: 'MOVE_MENU_ITEM_BEGIN',
    MOVE_MENU_ITEM_ERROR: 'MOVE_MENU_ITEM_ERROR',
    MOVE_MENU_ITEM_SUCCESS_HIDE: 'MOVE_MENU_ITEM_SUCCESS_HIDE',
    MOVE_MENU_ITEM_SUCCESS_SHOW: 'MOVE_MENU_ITEM_SUCCESS_SHOW',

    DELETE_MENU_ITEM_BEGIN: 'DELETE_MENU_ITEM_BEGIN',
    DELETE_MENU_ITEM_ERROR: 'DELETE_MENU_ITEM_ERROR',
    DELETE_MENU_ITEM_SUCCESS: 'DELETE_MENU_ITEM_SUCCESS',

    CHANGE_MENU_LANGUAGE: 'CHANGE_MENU_LANGUAGE',
    CHANGE_LANGUAGE_WAITING_STATE: 'CHANGE_LANGUAGE_WAITING_STATE',

    GET_MENU_SETTINGS_BEGIN: 'GET_MENU_SETTINGS_BEGIN',
    GET_MENU_SETTINGS_ERROR: 'GET_MENU_SETTINGS_ERROR',
    GET_MENU_SETTINGS_SUCCESS: 'GET_MENU_SETTINGS_SUCCESS',

    // Moveform controls
    MOVE_FORM_CONTROL_BEGIN: 'MOVE_FORM_CONTROL_BEGIN',
    MOVE_FORM_CONTROL_SUCCESS: 'MOVE_FORM_CONTROL_SUCCESS',
    MOVE_FORM_CONTROL_ERROR: 'MOVE_FORM_CONTROL_ERROR',

    CHANGE_CONTROL_WIZARD_STATE_BEGIN: 'CHANGE_CONTROL_WIZARD_STATE_BEGIN',
    CHANGE_CONTROL_WIZARD_STATE_SUCCESS: 'CHANGE_CONTROL_WIZARD_STATE_SUCCESS',
    CHANGE_CONTROL_WIZARD_STATE_ERROR: 'CHANGE_CONTROL_WIZARD_STATE_ERROR',


    EDIT_FORM_ITEM_BEGIN: 'EDIT_FORM_ITEM_BEGIN',
    EDIT_FORM_ITEM_SUCCESS: 'EDIT_FORM_ITEM_SUCCESS',
    EDIT_FORM_ITEM_ERROR: 'EDIT_FORM_ITEM_ERROR',
    CHANGE_REQUIRED_FORM_ITEM_SUCCESS: 'CHANGE_REQUIRED_FORM_ITEM_SUCCESS',


    GET_CONFIGS_BEGIN: 'GET_CONFIGS_BEGIN',
    GET_CONFIGS_ERROR: 'GET_CONFIGS_ERROR',
    GET_CONFIGS_SUCCESS: 'GET_CONFIGS_SUCCESS',

    GET_UI_SETTINGS_BEGIN: 'GET_UI_SETTINGS_BEGIN',
    GET_UI_SETTINGS_SUCCESS: 'GET_UI_SETTINGS_SUCCESS',
    GET_UI_SETTINGS_ERROR: 'GET_UI_SETTINGS_ERROR',

    SET_UI_SETTINGS_BEGIN: 'SET_UI_SETTINGS_BEGIN',
    SET_UI_SETTINGS_SUCCESS: 'SET_UI_SETTINGS_SUCCESS',
    SET_UI_SETTINGS_ERROR: 'SET_UI_SETTINGS_ERROR',

    SET_UI_SETTING: 'SET_UI_SETTING',
    SET_UI_SETTINGS: 'SET_UI_SETTINGS',
    RESET_UI_SETTINGS: 'RESET_UI_SETTINGS',
    SET_UI_GROUP: 'SET_UI_GROUP',
    GET_TEMPLATE_COLOR_SETS_BEGIN: 'GET_TEMPLATE_COLOR_SETS_BEGIN',
    GET_TEMPLATE_COLOR_SETS_ERROR: 'GET_TEMPLATE_COLOR_SETS_ERROR',
    GET_TEMPLATE_COLOR_SETS_SUCCESS: 'GET_TEMPLATE_COLOR_SETS_SUCCESS',

    GET_CONTROLS_TREE_BEGIN: 'GET_CONTROLS_TREE_BEGIN',
    SET_SITE_VERSION: 'SET_SITE_VERSION',
    GET_CONTROLS_TREE_SUCCESS: 'GET_CONTROLS_TREE_SUCCESS',
    GET_CONTROLS_TREE_AND_KEEP_OLD_BEGIN: 'GET_CONTROLS_TREE_AND_KEEP_OLD_BEGIN',
    GET_CONTROLS_TREE_ERROR: 'GET_CONTROLS_TREE_ERROR',

    GET_LOGIN_FORM_OPTION_BEGIN: 'GET_LOGIN_FORM_OPTION_BEGIN',
    GET_LOGIN_FORM_OPTION_ERROR: 'GET_LOGIN_FORM_OPTION_ERROR',
    GET_LOGIN_FORM_OPTION_SUCCESS: 'GET_LOGIN_FORM_OPTION_SUCCESS',
    SET_LOGIN_FORM_OPTION_BEGIN: 'SET_LOGIN_FORM_OPTION_BEGIN',
    SET_LOGIN_FORM_OPTION_ERROR: 'SET_LOGIN_FORM_OPTION_ERROR',
    SET_LOGIN_FORM_OPTION_SUCCESS: 'SET_LOGIN_FORM_OPTION_SUCCESS',

    GET_FORGOT_USERNAME_STATE_BEGIN: 'GET_FORGOT_USERNAME_STATE_BEGIN',
    GET_FORGOT_USERNAME_STATE_ERROR: 'GET_FORGOT_USERNAME_STATE_ERROR',
    GET_FORGOT_USERNAME_STATE_SUCCESS: 'GET_FORGOT_USERNAME_STATE_SUCCESS',
    SWITCH_FORGOT_USERNAME_STATE_BEGIN: 'SWITCH_FORGOT_USERNAME_STATE_BEGIN',
    SWITCH_FORGOT_USERNAME_STATE_ERROR: 'SWITCH_FORGOT_USERNAME_STATE_ERROR',
    SWITCH_FORGOT_USERNAME_STATE_SUCCESS: 'SWITCH_FORGOT_USERNAME_STATE_SUCCESS',

    SAVE_MENU_ITEM_ERROR: 'SAVE_MENU_ITEM_ERROR',
    SAVE_MENU_ITEM_SUCCESS: 'SAVE_MENU_ITEM_SUCCESS',

    //TIMER CONTROLER
    GET_TIMER_CONTROL_BEGIN: 'GET_TIMER_CONTROL_BEGIN',
    GET_TIMER_CONTROL_SUCCESS: 'GET_TIMER_CONTROL_SUCCESS',
    GET_TIMER_CONTROL_ERROR: 'GET_TIMER_CONTROL_ERROR',

    EDIT_TIMER_CONTROL_BEGIN: 'EDIT_TIMER_CONTROL_BEGIN',
    EDIT_TIMER_CONTROL_SUCCESS: 'EDIT_TIMER_CONTROL_SUCCESS',
    EDIT_TIMER_CONTROL_ERROR: 'EDIT_TIMER_CONTROL_ERROR',

    //SOCIAL MEDIA
    GET_SOCIAL_ITEMS_BEGIN: 'GET_SOCIAL_ITEMS_BEGIN',
    GET_SOCIAL_ITEMS_ERROR: 'GET_SOCIAL_ITEMS_ERROR',
    GET_SOCIAL_ITEMS_SUCCESS: 'GET_SOCIAL_ITEMS_SUCCESS',
    EDIT_SOCIAL_ITEMS_BEGIN: 'EDIT_SOCIAL_ITEMS_BEGIN',
    EDIT_SOCIAL_ITEMS_ERROR: 'EDIT_SOCIAL_ITEMS_ERROR',
    EDIT_SOCIAL_ITEMS_SUCCESS: 'EDIT_SOCIAL_ITEMS_SUCCESS',
    CHANGE_SOCIAL_SECTION_VISIBILITY_BEGIN: 'CHANGE_SOCIAL_SECTION_VISIBILITY_BEGIN',
    CHANGE_SOCIAL_SECTION_VISIBILITY_ERROR: 'CHANGE_SOCIAL_SECTION_VISIBILITY_ERROR',
    CHANGE_SOCIAL_SECTION_VISIBILITY_SUCCESS: 'CHANGE_SOCIAL_SECTION_VISIBILITY_SUCCESS',
    CHANGE_SOCIAL_VISIBILITY_BEGIN: 'CHANGE_SECTION_VISIBILITY_BEGIN',
    CHANGE_SOCIAL_VISIBILITY_ERROR: 'CHANGE_SECTION_VISIBILITY_ERROR',
    CHANGE_SOCIAL_VISIBILITY_SUCCESS: 'CHANGE_SECTION_VISIBILITY_SUCCESS',
    MOVE_SOCIAL_ITEM_BEGIN: 'MOVE_SOCIAL_ITEM_BEGIN',
    MOVE_SOCIAL_ITEM_ERROR: 'MOVE_SOCIAL_ITEM_ERROR',
    MOVE_SOCIAL_ITEM_SUCCESS: 'MOVE_SOCIAL_ITEM_SUCCESS',


    //PAYMENT CONTROLS
    GET_PAYMENT_CONTROLS_BEGIN: 'GET_PAYMENT_CONTROLS_BEGIN',
    GET_PAYMENT_CONTROLS_ERROR: 'GET_PAYMENT_CONTROLS_ERROR',
    GET_PAYMENT_CONTROLS_SUCCESS: 'GET_PAYMENT_CONTROLS_SUCCESS',

    GET_AVAILABLE_PAYMENT_CONTROLS_BEGIN: 'GET_AVAILABLE_PAYMENT_CONTROLS_BEGIN',
    GET_AVAILABLE_PAYMENT_CONTROLS_ERROR: 'GET_AVAILABLE_PAYMENT_CONTROLS_ERROR',
    GET_AVAILABLE_PAYMENT_CONTROLS_SUCCESS: 'GET_AVAILABLE_PAYMENT_CONTROLS_SUCCESS',

    ADD_PAYMENT_CONTROLS_BEGIN: 'ADD_PAYMENT_CONTROLS_BEGIN',
    ADD_PAYMENT_CONTROLS_ERROR: 'ADD_PAYMENT_CONTROLS_ERROR',
    ADD_PAYMENT_CONTROLS_SUCCESS: 'ADD_PAYMENT_CONTROLS_SUCCESS',

    EDIT_PAYMENT_CONTROLS_BEGIN: 'EDIT_PAYMENT_CONTROLS_BEGIN',
    EDIT_PAYMENT_CONTROLS_ERROR: 'EDIT_PAYMENT_CONTROLS_ERROR',
    EDIT_PAYMENT_CONTROLS_SUCCESS: 'EDIT_PAYMENT_CONTROLS_SUCCESS',

    DELETE_PAYMENT_CONTROLS_BEGIN: 'DELETE_PAYMENT_CONTROLS_BEGIN',
    DELETE_PAYMENT_CONTROLS_ERROR: 'DELETE_PAYMENT_CONTROLS_ERROR',
    DELETE_PAYMENT_CONTROLS_SUCCESS: 'DELETE_PAYMENT_CONTROLS_SUCCESS',

    CHANGE_PAYMENT_CONTROL_VISIBILITY_BEGIN: 'CHANGE_PAYMENT_CONTROL_VISIBILITY_BEGIN',
    CHANGE_PAYMENT_CONTROL_VISIBILITY_ERROR: 'CHANGE_PAYMENT_CONTROL_VISIBILITY_ERROR',
    CHANGE_PAYMENT_CONTROL_VISIBILITY_SUCCESS: 'CHANGE_PAYMENT_CONTROL_VISIBILITY_SUCCESS',

    SET_PAYMENT_LOGO_SETTINGS_BEGIN: 'SET_PAYMENT_LOGO_SETTINGS_BEGIN',
    SET_PAYMENT_LOGO_SETTINGS_ERROR: 'SET_PAYMENT_LOGO_SETTINGS_ERROR',
    SET_PAYMENT_LOGO_SETTINGS_SUCCESS: 'SET_PAYMENT_LOGO_SETTINGS_SUCCESS',
    GET_PAYMENT_LOGO_SETTINGS_BEGIN: 'GET_PAYMENT_LOGO_SETTINGS_BEGIN',
    GET_PAYMENT_LOGO_SETTINGS_ERROR: 'GET_PAYMENT_LOGO_SETTINGS_ERROR',
    GET_PAYMENT_LOGO_SETTINGS_SUCCESS: 'GET_PAYMENT_LOGO_SETTINGS_SUCCESS',

    //PROVIDERS CONTROLS
    GET_PROVIDER_CONTROLS_BEGIN: 'GET_PROVIDER_CONTROLS_BEGIN',
    GET_PROVIDER_CONTROLS_ERROR: 'GET_PROVIDER_CONTROLS_ERROR',
    GET_PROVIDER_CONTROLS_SUCCESS: 'GET_PROVIDER_CONTROLS_SUCCESS',

    GET_AVAILABLE_PROVIDERS_BEGIN: 'GET_AVAILABLE_PROVIDERS_BEGIN',
    GET_AVAILABLE_PROVIDERS_ERROR: 'GET_AVAILABLE_PROVIDERS_ERROR',
    GET_AVAILABLE_PROVIDERS_SUCCESS: 'GET_AVAILABLE_PROVIDERS_SUCCESS',

    EDIT_PROVIDER_CONTROL_BEGIN: 'EDIT_PROVIDER_CONTROL_BEGIN',
    EDIT_PROVIDER_CONTROL_ERROR: 'EDIT_PROVIDER_CONTROL_ERROR',
    EDIT_PROVIDER_CONTROL_SUCCESS: 'EDIT_PROVIDER_CONTROL_SUCCESS',

    ADD_PROVIDER_CONTROLS_BEGIN: 'ADD_PROVIDER_CONTROLS_BEGIN',
    ADD_PROVIDER_CONTROLS_ERROR: 'ADD_PROVIDER_CONTROLS_ERROR',
    ADD_PROVIDER_CONTROLS_SUCCESS: 'ADD_PROVIDER_CONTROLS_SUCCESS',

    DELETE_PROVIDER_CONTROLS_BEGIN: 'DELETE_PROVIDER_CONTROLS_BEGIN',
    DELETE_PROVIDER_CONTROLS_ERROR: 'DELETE_PROVIDER_CONTROLS_ERROR',
    DELETE_PROVIDER_CONTROLS_SUCCESS: 'DELETE_PROVIDER_CONTROLS_SUCCESS',

    CHANGE_PROVIDER_SECTION_VISIBILITY_BEGIN: 'CHANGE_PROVIDER_SECTION_VISIBILITY_BEGIN',
    CHANGE_PROVIDER_SECTION_VISIBILITY_ERROR: 'CHANGE_PROVIDER_SECTION_VISIBILITY_ERROR',
    CHANGE_PROVIDER_SECTION_VISIBILITY_SUCCESS: 'CHANGE_PROVIDER_SECTION_VISIBILITY_SUCCESS',

    CHANGE_PROVIDER_VISIBILITY_BEGIN: 'CHANGE_PROVIDER_VISIBILITY_BEGIN',
    CHANGE_PROVIDER_VISIBILITY_ERROR: 'CHANGE_PROVIDER_VISIBILITY_ERROR',
    CHANGE_PROVIDER_VISIBILITY_SUCCESS: 'CHANGE_PROVIDER_VISIBILITY_SUCCESS',

    SET_PROVIDER_LOGO_SETTINGS_BEGIN: 'SET_PROVIDER_LOGO_SETTINGS_BEGIN',
    SET_PROVIDER_LOGO_SETTINGS_ERROR: 'SET_PROVIDER_LOGO_SETTINGS_ERROR',
    SET_PROVIDER_LOGO_SETTINGS_SUCCESS: 'SET_PROVIDER_LOGO_SETTINGS_SUCCESS',
    GET_PROVIDER_LOGO_SETTINGS_BEGIN: 'GET_PROVIDER_LOGO_SETTINGS_BEGIN',
    GET_PROVIDER_LOGO_SETTINGS_ERROR: 'GET_PROVIDER_LOGO_SETTINGS_ERROR',
    GET_PROVIDER_LOGO_SETTINGS_SUCCESS: 'GET_PROVIDER_LOGO_SETTINGS_SUCCESS',
    //DEVICE MODE

    SET_DEVICE_MODE: 'SET_DEVICE_MODE',

    // Products
    REMOVE_SPORT_BEGIN: 'REMOVE_SPORT_BEGIN',
    REMOVE_SPORT_ERROR: 'REMOVE_SPORT_ERROR',
    REMOVE_SPORT_SUCCESS: 'REMOVE_SPORT_SUCCESS',
    ADD_SPORT_BEGIN: 'ADD_SPORT_BEGIN',
    ADD_SPORT_ERROR: 'ADD_SPORT_ERROR',
    ADD_SPORT_SUCCESS: 'ADD_SPORT_SUCCESS',
    CHANGE_SPORT_TYPE_BEGIN: 'CHANGE_SPORT_TYPE_BEGIN',
    CHANGE_SPORT_TYPE_ERROR: 'CHANGE_SPORT_TYPE_ERROR',
    CHANGE_SPORT_TYPE_SUCCESS: 'CHANGE_SPORT_TYPE_SUCCESS',
    ADD_CASINO_BEGIN: 'ADD_CASINO_BEGIN',
    ADD_CASINO_ERROR: 'ADD_CASINO_ERROR',
    ADD_CASINO_SUCCESS: 'ADD_CASINO_SUCCESS',
    CHANGE_GAME_STATE_BEGIN: 'CHANGE_GAME_STATE_BEGIN',
    CHANGE_GAME_STATE_ERROR: 'CHANGE_GAME_STATE_ERROR',
    CHANGE_GAME_STATE_SUCCESS: 'CHANGE_GAME_STATE_SUCCESS',
    REMOVE_CASINO_BEGIN: 'REMOVE_CASINO_BEGIN',
    REMOVE_CASINO_ERROR: 'REMOVE_CASINO_ERROR',
    REMOVE_CASINO_SUCCESS: 'REMOVE_CASINO_SUCCESS',
    GET_SPORT_PRODUCTS_BEGIN: 'GET_SPORT_PRODUCTS_BEGIN',
    GET_SPORT_PRODUCTS_ERROR: 'GET_SPORT_PRODUCTS_ERROR',
    GET_SPORT_PRODUCTS_SUCCESS: 'GET_SPORT_PRODUCTS_SUCCESS',
    GET_ALL_PROVIDERS_BEGIN: 'GET_ALL_PROVIDERS_BEGIN',
    GET_ALL_PROVIDERS_SUCCESS: 'GET_ALL_PROVIDERS_SUCCESS',

    GET_GAMES_BEGIN: 'GET_GAMES_BEGIN',
    GET_GAMES_ERROR: 'GET_GAMES_ERROR',
    GET_GAMES_SUCCESS: 'GET_GAMES_SUCCESS',
    GET_PROVIDERS_BEGIN: 'GET_PROVIDERS_BEGIN',
    GET_ALL_PROVIDERS_ERROR: 'GET_ALL_PROVIDERS_ERROR',
    GET_PROVIDERS_SUCCESS: 'GET_PROVIDERS_SUCCESS',
    GET_CATEGORIES_BEGIN: 'GET_CATEGORIES_BEGIN',
    GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',

    //SEO
    GET_SEO_PAGES_BEGIN: 'GET_SEO_PAGES_BEGIN',
    GET_SEO_PAGES_ERROR: 'GET_SEO_PAGES_ERROR',
    GET_SEO_PAGES_SUCCESS: 'GET_SEO_PAGES_SUCCESS',
    SET_SEO_SETTINGS_BEGIN: 'SET_SEO_SETTINGS_BEGIN',
    SET_SEO_SETTINGS_ERROR: 'SET_SEO_SETTINGS_ERROR',
    SET_SEO_SETTINGS_SUCCESS: 'SET_SEO_SETTINGS_SUCCESS',
    GET_SEO_SETTINGS_SUCCESS: 'GET_SEO_SETTINGS_SUCCESS',
    GET_SEO_SETTINGS_ERROR: 'GET_SEO_SETTINGS_ERROR',
    GET_SEO_SETTINGS_BEGIN: 'GET_SEO_SETTINGS_BEGIN',
    GET_OPEN_GRAPH_SETTINGS_BEGIN: 'GET_OPEN_GRAPH_SETTINGS_BEGIN',
    GET_OPEN_GRAPH_SETTINGS_ERROR: 'GET_OPEN_GRAPH_SETTINGS_ERROR',
    GET_OPEN_GRAPH_SETTINGS_SUCCESS: 'GET_OPEN_GRAPH_SETTINGS_SUCCESS',
    GET_TWITTER_SETTINGS_BEGIN: 'GET_TWITTER_SETTINGS_BEGIN',
    GET_TWITTER_SETTINGS_ERROR: 'GET_TWITTER_SETTINGS_ERROR',
    GET_TWITTER_SETTINGS_SUCCESS: 'GET_TWITTER_SETTINGS_SUCCESS',
    SET_TWITTER_SETTINGS_BEGIN: 'SET_TWITTER_SETTINGS_BEGIN',
    SET_TWITTER_SETTINGS_SUCCESS: 'SET_TWITTER_SETTINGS_SUCCESS',
    SET_TWITTER_SETTINGS_ERROR: 'SET_TWITTER_SETTINGS_ERROR',
    SET_OPEN_GRAPH_SETTINGS_BEGIN: 'SET_OPEN_GRAPH_SETTINGS_BEGIN',
    SET_OPEN_GRAPH_SETTINGS_ERROR: 'SET_OPEN_GRAPH_SETTINGS_ERROR',
    SET_OPEN_GRAPH_SETTINGS_SUCCESS: 'SET_OPEN_GRAPH_SETTINGS_SUCCESS',
    SET_SEO_SCHEMA_SETTINGS_BEGIN: 'SET_SEO_SCHEMA_SETTINGS_BEGIN',
    SET_SEO_SCHEMA_SETTINGS_ERROR: 'SET_SEO_SCHEMA_SETTINGS_ERROR',
    SET_SEO_SCHEMA_SETTINGS_SUCCESS: 'SET_SEO_SCHEMA_SETTINGS_SUCCESS',
    GET_SEO_SCHEMA_SETTINGS_BEGIN: 'GET_SEO_SCHEMA_SETTINGS_BEGIN',
    GET_SEO_SCHEMA_SETTINGS_ERROR: 'GET_SEO_SCHEMA_SETTINGS_ERROR',
    GET_SEO_SCHEMA_SETTINGS_SUCCESS: 'GET_SEO_SCHEMA_SETTINGS_SUCCESS',
    GET_SITEMAP_SETTINGS: 'GET_SITEMAP_SETTINGS',
    SET_SITEMAP_SETTINGS: 'SET_SITEMAP_SETTINGS',
    GET_OPENGRAPH_RULES_BEGIN: 'GET_OPENGRAPH_RULES_BEGIN',
    GET_OPENGRAPH_RULES_ERROR: 'GET_OPENGRAPH_RULES_ERROR',
    GET_OPENGRAPH_RULES_SUCCESS: 'GET_OPENGRAPH_RULES_SUCCESS',
    EDIT_OPENGRAPH_RULE_BEGIN: 'EDIT_OPENGRAPH_RULE_BEGIN',
    EDIT_OPENGRAPH_RULE_ERROR: 'EDIT_OPENGRAPH_RULE_ERROR',
    EDIT_OPENGRAPH_RULE_SUCCESS: 'EDIT_OPENGRAPH_RULE_SUCCESS',
    DELETE_OPENGRAPH_RULES_BEGIN: 'DELETE_OPENGRAPH_RULES_BEGIN',
    DELETE_OPENGRAPH_RULES_ERROR: 'DELETE_OPENGRAPH_RULES_ERROR',
    DELETE_OPENGRAPH_RULES_SUCCESS: 'DELETE_OPENGRAPH_RULES_SUCCESS',

 

    CHANGE_BACKGROUND: 'CHANGE_BACKGROUND',

    // License
    GET_PROJECT_ALL_LICENSES_BEGIN: 'GET_PROJECT_ALL_LICENSES_BEGIN',
    GET_PROJECT_ALL_LICENSES_ERROR: 'GET_PROJECT_ALL_LICENSES_ERROR',
    GET_PROJECT_ALL_LICENSES_SUCCESS: 'GET_PROJECT_ALL_LICENSES_SUCCESS',
    GET_LICENSE_SETTINGS_BEGIN: 'GET_LICENSE_SETTINGS_BEGIN',
    GET_LICENSE_SETTINGS_ERROR: 'GET_LICENSE_SETTINGS_ERROR',
    GET_LICENSE_SETTINGS_SUCCESS: 'GET_LICENSE_SETTINGS_SUCCESS',
    ADD_LICENSE_ERROR: 'ADD_LICENSE_ERROR',
    ADD_LICENSE_SUCCESS: 'ADD_LICENSE_SUCCESS',
    ADD_LICENSE_BEGIN: 'ADD_LICENSE_BEGIN',
    DELETE_LICENSE_BEGIN: 'DELETE_LICENSE_BEGIN',
    DELETE_LICENSE_ERROR: 'DELETE_LICENSE_ERROR',
    DELETE_LICENSE_SUCCESS: 'DELETE_LICENSE_SUCCESS',

    // Featured Products
    GET_FEATURED_PRODUCTS_BEGIN: 'GET_FEATURED_PRODUCTS_BEGIN',
    GET_FEATURED_PRODUCTS_ERROR: 'GET_FEATURED_PRODUCTS_ERROR',
    GET_FEATURED_PRODUCTS_SUCCESS: 'GET_FEATURED_PRODUCTS_SUCCESS',

    ADD_FEATURED_PRODUCTS_ERROR: 'ADD_FEATURED_PRODUCTS_ERROR',
    ADD_FEATURED_PRODUCTS_SUCCESS: 'ADD_FEATURED_PRODUCTS_SUCCESS',
    ADD_FEATURED_PRODUCTS_BEGIN: 'ADD_FEATURED_PRODUCTS_BEGIN',

    CHANGE_FEATURED_PRODUCTS_VISIBILITY_BEGIN: 'CHANGE_FEATURED_PRODUCTS_VISIBILITY_BEGIN',
    CHANGE_FEATURED_PRODUCTS_VISIBILITY_ERROR: 'CHANGE_FEATURED_PRODUCTS_VISIBILITY_ERROR',
    CHANGE_FEATURED_PRODUCTS_VISIBILITY_SUCCESS: 'CHANGE_FEATURED_PRODUCTS_VISIBILITY_SUCCESS',

    DELETE_FEATURED_PRODUCTS_BEGIN: 'DELETE_FEATURED_PRODUCTS_BEGIN',
    DELETE_FEATURED_PRODUCTS_ERROR: 'DELETE_FEATURED_PRODUCTS_ERROR',
    DELETE_FEATURED_PRODUCTS_SUCCESS: 'DELETE_FEATURED_PRODUCTS_SUCCESS',

    MOVE_FEATURED_PRODUCTS_BEGIN: 'MOVE_FEATURED_PRODUCTS_BEGIN',
    MOVE_FEATURED_PRODUCTS_ERROR: 'MOVE_FEATURED_PRODUCTS_ERROR',
    MOVE_FEATURED_PRODUCTS_SUCCESS: 'MOVE_FEATURED_PRODUCTS_SUCCESS',

    EDIT_FEATURED_PRODUCTS_BEGIN: 'EDIT_FEATURED_PRODUCTS_BEGIN',
    EDIT_FEATURED_PRODUCTS_ERROR: 'EDIT_FEATURED_PRODUCTS_ERROR',
    EDIT_FEATURED_PRODUCTS_SUCCESS: 'EDIT_FEATURED_PRODUCTS_SUCCESS',

    UPDATE_ABILITIES: 'UPDATE_ABILITIES',

    // Promotions
    GET_PROMOTIONS_BEGIN: 'GET_PROMOTIONS_BEGIN',
    GET_PROMOTIONS_ERROR: 'GET_PROMOTIONS_ERROR',
    GET_PROMOTIONS_SUCCESS: 'GET_PROMOTIONS_SUCCESS',
    ADD_PROMOTION_BEGIN: 'ADD_PROMOTION_BEGIN',
    ADD_PROMOTION_ERROR: 'ADD_PROMOTION_ERROR',
    ADD_PROMOTION_SUCCESS: 'ADD_PROMOTION_SUCCESS',
    EDIT_PROMOTION_BEGIN: 'EDIT_PROMOTION_BEGIN',
    EDIT_PROMOTION_ERROR: 'EDIT_PROMOTION_ERROR',
    EDIT_PROMOTION_SUCCESS: 'EDIT_PROMOTION_SUCCESS',
    DELETE_PROMOTION_BEGIN: 'DELETE_PROMOTION_BEGIN',
    DELETE_PROMOTION_ERROR: 'DELETE_PROMOTION_ERROR',
    DELETE_PROMOTION_SUCCESS: 'DELETE_PROMOTION_SUCCESS',
    MOVE_PROMOTION_BEGIN: 'MOVE_PROMOTION_BEGIN',
    MOVE_PROMOTION_ERROR: 'MOVE_PROMOTION_ERROR',
    MOVE_PROMOTION_SUCCESS: 'MOVE_PROMOTION_SUCCESS',
    CHANGE_PROMOTION_VISIBILITY_BEGIN: 'CHANGE_PROMOTION_VISIBILITY_BEGIN',
    CHANGE_PROMOTION_VISIBILITY_ERROR: 'CHANGE_PROMOTION_VISIBILITY_ERROR',
    CHANGE_PROMOTION_VISIBILITY_SUCCESS: 'CHANGE_PROMOTION_VISIBILITY_SUCCESS',
    HIGHLIGHT_PROMOTION_BEGIN: 'HIGHLIGHT_PROMOTION_BEGIN',
    HIGHLIGHT_PROMOTION_SUCCESS: 'HIGHLIGHT_PROMOTION_SUCCESS',
    HIGHLIGHT_PROMOTION_ERROR: 'HIGHLIGHT_PROMOTION_ERROR',

    GET_PROMOTIONS_CATEGORIES_BEGIN: 'GET_PROMOTIONS_CATEGORIES_BEGIN',
    GET_PROMOTIONS_CATEGORIES_ERROR: 'GET_PROMOTIONS_CATEGORIES_ERROR',
    GET_PROMOTIONS_CATEGORIES_SUCCESS: 'GET_PROMOTIONS_CATEGORIES_SUCCESS',
    ADD_PROMOTION_CATEGORY_BEGIN: 'ADD_PROMOTION_CATEGORY_BEGIN',
    ADD_PROMOTION_CATEGORY_ERROR: 'ADD_PROMOTION_CATEGORY_ERROR',
    ADD_PROMOTION_CATEGORY_SUCCESS: 'ADD_PROMOTION_CATEGORY_SUCCESS',
    DELETE_PROMOTION_CATEGORY_BEGIN: 'DELETE_PROMOTION_CATEGORY_BEGIN',
    DELETE_PROMOTION_CATEGORY_ERROR: 'DELETE_PROMOTION_CATEGORY_ERROR',
    DELETE_PROMOTION_CATEGORY_SUCCESS: 'DELETE_PROMOTION_CATEGORY_SUCCESS',
    EDIT_PROMOTION_CATEGORY_BEGIN: 'EDIT_PROMOTION_CATEGORY_BEGIN',
    EDIT_PROMOTION_CATEGORY_ERROR: 'EDIT_PROMOTION_CATEGORY_ERROR',
    EDIT_PROMOTION_CATEGORY_SUCCESS: 'EDIT_PROMOTION_CATEGORY_SUCCESS',
    GET_CATEGORIES_ICONS_VISIBILITY_BEGIN: 'GET_CATEGORIES_ICONS_VISIBILITY_BEGIN',
    GET_CATEGORIES_ICONS_VISIBILITY_ERROR: 'GET_CATEGORIES_ICONS_VISIBILITY_ERROR',
    GET_CATEGORIES_ICONS_VISIBILITY_SUCCESS: 'GET_CATEGORIES_ICONS_VISIBILITY_SUCCESS',
    CHANGE_CATEGORIES_ICONS_VISIBILITY_BEGIN: 'CHANGE_CATEGORIES_ICONS_VISIBILITY_BEGIN',
    CHANGE_CATEGORIES_ICONS_VISIBILITY_ERROR: 'CHANGE_CATEGORIES_ICONS_VISIBILITY_ERROR',
    CHANGE_CATEGORIES_ICONS_VISIBILITY_SUCCESS: 'CHANGE_CATEGORIES_ICONS_VISIBILITY_SUCCESS',
    MOVE_PROMOTION_CATEGORY_BEGIN: 'MOVE_PROMOTION_CATEGORY_BEGIN',
    MOVE_PROMOTION_CATEGORY_ERROR: 'MOVE_PROMOTION_CATEGORY_ERROR',
    MOVE_PROMOTION_CATEGORY_SUCCESS: 'MOVE_PROMOTION_CATEGORY_SUCCESS',
    GET_CATEGORY_ICONS_BEGIN: 'GET_CATEGORY_ICONS_BEGIN',
    GET_CATEGORY_ICONS_ERROR: 'GET_CATEGORY_ICONS_ERROR',
    GET_CATEGORY_ICONS_SUCCESS: 'GET_CATEGORY_ICONS_SUCCESS',
    GET_TARGET_GROUPS_SUCCESS: 'GET_TARGET_GROUPS_SUCCESS',

    // Account
    GET_ROLES_LIST_BEGIN: 'GET_ROLES_LIST_BEGIN',
    GET_ROLES_LIST_ERROR: 'GET_ROLES_LIST_ERROR',
    GET_ROLES_LIST_SUCCESS: 'GET_ROLES_LIST_SUCCESS',
    GET_ROLE_BEGIN: 'GET_ROLE_BEGIN',
    GET_ROLE_ERROR: 'GET_ROLE_ERROR',
    GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
    GET_ROLE_PERMISSIONS_BEGIN: 'GET_ROLE_PERMISSIONS_BEGIN',
    GET_ROLE_PERMISSIONS_ERROR: 'GET_ROLE_PERMISSIONS_ERROR',
    GET_ROLE_PERMISSIONS_SUCCESS: 'GET_ROLE_PERMISSIONS_SUCCESS',
    GET_ROLE_PERMISSIONS_FOR_CHECKING_SUCCESS: 'GET_ROLE_PERMISSIONS_FOR_CHECKING_SUCCESS',
    GET_ROLE_PERMISSIONS_FOR_CHECKING_BEGIN: 'GET_ROLE_PERMISSIONS_FOR_CHECKING_BEGIN',
    GET_ROLE_PERMISSIONS_FOR_CHECKING_ERROR: 'GET_ROLE_PERMISSIONS_FOR_CHECKING_ERROR',
    ADD_ROLE_BEGIN: 'ADD_ROLE_BEGIN',
    ADD_ROLE_ERROR: 'ADD_ROLE_ERROR',
    ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
    EDIT_ROLE_BEGIN: 'EDIT_ROLE_BEGIN',
    EDIT_ROLE_ERROR: 'EDIT_ROLE_ERROR',
    EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
    DELETE_ROLE_BEGIN: 'DELETE_ROLE_BEGIN',
    DELETE_ROLE_ERROR: 'DELETE_ROLE_ERROR',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',

    GET_USERS_BEGIN: 'GET_USERS_BEGIN',
    GET_USERS_ERROR: 'GET_USERS_ERROR',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USER_BEGIN: 'GET_USER_BEGIN',
    GET_USER_ERROR: 'GET_USER_ERROR',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    CREATE_USER_BEGIN: 'CREATE_USER_BEGIN',
    CREATE_USER_ERROR: 'CREATE_USER_ERROR',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    EDIT_USER_BEGIN: 'EDIT_USER_BEGIN',
    EDIT_USER_ERROR: 'EDIT_USER_ERROR',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',

    HANDLE_REQUEST_BEGIN: 'HANDLE_REQUEST_BEGIN',
    HANDLE_REQUEST_ERROR: 'HANDLE_REQUEST_ERROR',
    HANDLE_REQUEST_SUCCESS: 'HANDLE_REQUEST_SUCCESS',
    GET_REQUESTS_BEGIN: 'GET_REQUESTS_BEGIN',
    GET_REQUESTS_ERROR: 'GET_REQUESTS_ERROR',
    GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
    GET_REQUESTS_COUNT_SUCCESS: 'GET_REQUESTS_COUNT_SUCCESS',

    //Page Management
    GET_PAGES_BEGIN: 'GET_PAGES_BEGIN',
    GET_PAGES_ERROR: 'GET_PAGES_ERROR',
    GET_PAGES_SUCCESS: 'GET_PAGES_SUCCESS',
    GET_PAGE_CONTENTS_BEGIN: 'GET_PAGE_CONTENTS_BEGIN',
    GET_PAGE_CONTENTS_ERROR: 'GET_PAGE_CONTENTS_ERROR',
    GET_PAGE_CONTENTS_SUCCESS: 'GET_PAGE_CONTENTS_SUCCESS',
    GET_PAGE_CONTENT_BEGIN: 'GET_PAGE_CONTENT_BEGIN',
    GET_PAGE_CONTENT_AND_RESET_HISTORY_BEGIN: 'GET_PAGE_CONTENT_AND_RESET_HISTORY_BEGIN',
    GET_PAGE_CONTENT_ERROR: 'GET_PAGE_CONTENT_ERROR',
    GET_PAGE_CONTENT_SUCCESS: 'GET_PAGE_CONTENT_SUCCESS',
    ADD_PAGE_BEGIN: 'ADD_PAGE_BEGIN',
    ADD_PAGE_ERROR: 'ADD_PAGE_ERROR',
    ADD_PAGE_SUCCESS: 'ADD_PAGE_SUCCESS',
    EDIT_PAGE_BEGIN: 'EDIT_PAGE_BEGIN',
    EDIT_PAGE_ERROR: 'EDIT_PAGE_ERROR',
    EDIT_PAGE_SUCCESS: 'EDIT_PAGE_SUCCESS',
    DELETE_PAGE_BEGIN: 'DELETE_PAGE_BEGIN',
    DELETE_PAGE_ERROR: 'DELETE_PAGE_ERROR',
    DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
    SAVE_PAGE_CONTENT_BEGIN: 'SAVE_PAGE_CONTENT_BEGIN',
    SAVE_PAGE_CONTENT_ERROR: 'SAVE_PAGE_CONTENT_ERROR',
    SAVE_PAGE_CONTENT_SUCCESS: 'SAVE_PAGE_CONTENT_SUCCESS',
    GET_PAGE_URLS_BEGIN: 'GET_PAGE_URLS_BEGIN',
    GET_PAGE_URLS_ERROR: 'GET_PAGE_URLS_ERROR',
    GET_PAGE_URLS_SUCCESS: 'GET_PAGE_URLS_SUCCESS',
    GET_PAGE_HISTORY_BEGIN: 'GET_PAGE_HISTORY_BEGIN',
    GET_PAGE_HISTORY_ERROR: 'GET_PAGE_HISTORY_ERROR',
    GET_PAGE_HISTORY_SUCCESS: 'GET_PAGE_HISTORY_SUCCESS',

    // Popup management

    GET_POPUPS_BEGIN: 'GET_POPUPS_BEGIN',
    GET_POPUPS_ERROR: 'GET_POPUPS_ERROR',
    GET_POPUPS_SUCCESS: 'GET_POPUPS_SUCCESS',
    GET_POPUP_CONTENTS_BEGIN: 'GET_POPUP_CONTENTS_BEGIN',
    GET_POPUP_CONTENTS_ERROR: 'GET_POPUP_CONTENTS_ERROR',
    GET_POPUP_CONTENTS_SUCCESS: 'GET_POPUP_CONTENTS_SUCCESS',
    GET_POPUP_CONTENT_BEGIN: 'GET_POPUP_CONTENT_BEGIN',
    GET_POPUP_CONTENT_ERROR: 'GET_POPUP_CONTENT_ERROR',
    GET_POPUP_CONTENT_SUCCESS: 'GET_POPUP_CONTENT_SUCCESS',
    GET_POPUP_SETTINGS_BEGIN: 'GET_POPUP_SETTINGS_BEGIN',
    GET_POPUP_SETTINGS_ERROR: 'GET_POPUP_SETTINGS_ERROR',
    GET_POPUP_SETTINGS_SUCCESS: '    GET_POPUP_SETTINGS_SUCCESS',
    SAVE_POPUP_SETTINGS_BEGIN: 'SAVE_POPUP_SETTINGS_BEGIN',
    SAVE_POPUP_SETTINGS_ERROR: 'SAVE_POPUP_SETTINGS_ERROR',
    SAVE_POPUP_SETTINGS_SUCCESS: 'SAVE_POPUP_SETTINGS_SUCCESS',
    GET_DEFAULT_POPUPS_BEGIN: 'GET_DEFAULT_POPUPS_BEGIN',
    GET_DEFAULT_POPUPS_ERROR: 'GET_DEFAULT_POPUPS_ERROR',
    GET_DEFAULT_POPUPS_SUCCESS: 'GET_DEFAULT_POPUPS_SUCCESS',

    ADD_POPUP_BEGIN: 'ADD_POPUP_BEGIN',
    ADD_POPUP_ERROR: 'ADD_POPUP_ERROR',
    ADD_POPUP_SUCCESS: 'ADD_POPUP_SUCCESS',
    EDIT_POPUP_BEGIN: 'EDIT_POPUP_BEGIN',
    EDIT_POPUP_ERROR: 'EDIT_POPUP_ERROR',
    EDIT_POPUP_SUCCESS: 'EDIT_POPUP_SUCCESS',
    SAVE_POPUP_CONTENT_BEGIN: 'SAVE_POPUP_CONTENT_BEGIN',
    SAVE_POPUP_CONTENT_ERROR: 'SAVE_POPUP_CONTENT_ERROR',
    SAVE_POPUP_CONTENT_SUCCESS: 'SAVE_POPUP_CONTENT_SUCCESS',
    DELETE_POPUP_BEGIN: 'DELETE_POPUP_BEGIN',
    DELETE_POPUP_ERROR: 'DELETE_POPUP_ERROR',
    DELETE_POPUP_SUCCESS: 'DELETE_POPUP_SUCCESS',
    GET_TEMPLATE_BEGIN: 'GET_TEMPLATE_BEGIN',
    GET_TEMPLATE_ERROR: 'GET_TEMPLATE_ERROR',
    GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
    EDIT_TEMPLATE_CONTENT_BEGIN: 'EDIT_TEMPLATE_CONTENT_BEGIN',
    EDIT_TEMPLATE_CONTENT_ERROR: 'EDIT_TEMPLATE_CONTENT_ERROR',
    EDIT_TEMPLATE_CONTENT_SUCCESS: 'EDIT_TEMPLATE_CONTENT_SUCCESS',
    GET_DISPLAY_MODE_BEGIN: 'GET_DISPLAY_MODE_BEGIN',
    GET_DISPLAY_MODE_ERROR: 'GET_DISPLAY_MODE_ERROR',
    GET_DISPLAY_MODE_SUCCESS: 'GET_DISPLAY_MODE_SUCCESS',
    SET_DISPLAY_MODE_BEGIN: 'SET_DISPLAY_MODE_BEGIN',
    SET_DISPLAY_MODE_ERROR: 'SET_DISPLAY_MODE_ERROR',
    SET_DISPLAY_MODE_SUCCESS: 'SET_DISPLAY_MODE_SUCCESS',

    //Background Image
    GET_BACKGROUND_IMAGES_BEGIN: 'GET_BACKGROUND_IMAGES_BEGIN',
    GET_BACKGROUND_IMAGES_SUCCESS: 'GET_BACKGROUND_IMAGES_SUCCESS',
    GET_BACKGROUND_IMAGES_ERROR: 'GET_BACKGROUND_IMAGES_ERROR',
    GET_BACKGROUND_IMAGE_PAGES_BEGIN: 'GET_BACKGROUND_IMAGE_PAGES_BEGIN',
    GET_BACKGROUND_IMAGE_PAGES_SUCCESS: 'GET_BACKGROUND_IMAGE_PAGES_SUCCESS',
    GET_BACKGROUND_IMAGE_PAGES_ERROR: 'GET_BACKGROUND_IMAGE_PAGES_ERROR',
    ADD_BACKGROUND_IMAGE_BEGIN: 'ADD_BACKGROUND_IMAGE_BEGIN',
    ADD_BACKGROUND_IMAGE_ERROR: 'ADD_BACKGROUND_IMAGE_ERROR',
    ADD_BACKGROUND_IMAGE_SUCCESS: 'ADD_BACKGROUND_IMAGE_SUCCESS',
    EDIT_BACKGROUND_IMAGE_BEGIN: 'EDIT_BACKGROUND_IMAGE_BEGIN',
    EDIT_BACKGROUND_IMAGE_ERROR: 'EDIT_BACKGROUND_IMAGE_ERROR',
    EDIT_BACKGROUND_IMAGE_SUCCESS: 'EDIT_BACKGROUND_IMAGE_SUCCESS',
    DELETE_BACKGROUND_IMAGE_BEGIN: 'DELETE_BACKGROUND_IMAGE_BEGIN',
    DELETE_BACKGROUND_IMAGE_ERROR: 'DELETE_BACKGROUND_IMAGE_ERROR',
    DELETE_BACKGROUND_IMAGE_SUCCESS: 'DELETE_BACKGROUND_IMAGE_SUCCESS',

    // Rules
    GET_RULES_BEGIN: 'GET_RULES_BEGIN',
    GET_RULES_ERROR: 'GET_RULES_ERROR',
    GET_RULES_SUCCESS: 'GET_RULES_SUCCESS',
    ADD_SIDEBAR_MENU_ITEM_BEGIN: 'ADD_SIDEBAR_MENU_ITEM_BEGIN',
    ADD_SIDEBAR_MENU_ITEM_ERROR: 'ADD_SIDEBAR_MENU_ITEM_ERROR',
    ADD_SIDEBAR_MENU_ITEM_SUCCESS: 'ADD_SIDEBAR_MENU_ITEM_SUCCESS',
    
    //Busines Rules
    GET_BUSINESS_RULES_LIST_BEGIN: 'GET_BUSINESS_RULES_LIST_BEGIN',
    GET_BUSINESS_RULES_LIST_ERROR: 'GET_BUSINESS_RULES_LIST_ERROR',
    GET_BUSINESS_RULES_LIST_SUCCESS: 'GET_BUSINESS_RULES_LIST_SUCCESS',
    GET_BUSINESS_RULE_BEGIN: 'GET_BUSINESS_RULE_BEGIN',
    GET_BUSINESS_RULE_ERROR: 'GET_BUSINESS_RULE_ERROR',
    GET_BUSINESS_RULE_SUCCESS: 'GET_BUSINESS_RULE_SUCCESS',
    ADD_BUSINESS_RULE_BEGIN: 'ADD_BUSINESS_RULE_BEGIN',
    ADD_BUSINESS_RULE_ERROR: 'ADD_BUSINESS_RULE_ERROR',
    ADD_BUSINESS_RULE_SUCCESS: 'ADD_BUSINESS_RULE_SUCCESS',
    EDIT_BUSINESS_RULE_BEGIN: 'EDIT_BUSINESS_RULE_BEGIN',
    EDIT_BUSINESS_RULE_ERROR: 'EDIT_BUSINESS_RULE_ERROR',
    EDIT_BUSINESS_RULE_SUCCESS: 'EDIT_BUSINESS_RULE_SUCCESS',
    DELETE_BUSINESS_RULE_BEGIN: 'DELETE_BUSINESS_RULE_BEGIN',
    DELETE_BUSINESS_RULE_ERROR: 'DELETE_BUSINESS_RULE_ERROR',
    DELETE_BUSINESS_RULE_SUCCESS: 'DELETE_BUSINESS_RULE_SUCCESS',
    GET_BUSINESS_RULE_TRIGGERS_BEGIN: 'GET_BUSINESS_RULE_TRIGGERS_BEGIN',
    GET_BUSINESS_RULE_TRIGGERS_ERROR: 'GET_BUSINESS_RULE_TRIGGERS_BEGIN',
    GET_BUSINESS_RULE_TRIGGERS_SUCCESS: 'GET_BUSINESS_RULE_TRIGGERS_SUCCESS',
    SET_CURRENT_BUSINESS_RULE: 'SET_CURRENT_BUSINESS_RULE',
    GET_BUSINESS_RULE_POPUPS_BEGIN: 'GET_BUSINESS_RULE_POPUPS_BEGIN',
    GET_BUSINESS_RULE_POPUPS_ERROR: 'GET_BUSINESS_RULE_POPUPS_ERROR',
    GET_BUSINESS_RULE_POPUPS_SUCCESS: 'GET_BUSINESS_RULE_POPUPS_SUCCESS',
    GET_POPUP_RULES_BEGIN: 'GET_POPUP_RULES_BEGIN',
    GET_POPUP_RULES_ERROR: 'GET_POPUP_RULES_ERROR',
    GET_POPUP_RULES_SUCCESS: 'GET_POPUP_RULES_SUCCESS',
    
    GET_ALL_COUNTRIES_SUCCESS : 'GET_ALL_COUNTRIES_SUCCESS',
    
    //WIDGETS

    GET_WIDGETS_BEGIN: 'GET_WIDGETS_BEGIN',
    GET_WIDGETS_ERROR: 'GET_WIDGETS_ERROR',
    GET_WIDGETS_SUCCESS: 'GET_WIDGETS_SUCCESS',

    // Announcment
    GET_ANNOUNCMENT_BEGIN:'GET_ANNOUNCMENT_BEGIN',
    GET_ANNOUNCMENT_ERROR:'GET_ANNOUNCMENT_ERROR',
    GET_ANNOUNCMENT_SUCCESS:'GET_ANNOUNCMENT_SUCCESS',
    ADD_ANNOUNCMENT_BEGIN:'ADD_ANNOUNCMENT_BEGIN',
    ADD_ANNOUNCMENT_ERROR:'ADD_ANNOUNCMENT_ERROR',
    ADD_ANNOUNCMENT_SUCCESS:'ADD_ANNOUNCMENT_SUCCESS',
    CHANGE_ANNOUNCMENT_VISIBILITY_BEGIN:'CHANGE_ANNOUNCMENT_VISIBILITY_BEGIN',
    CHANGE_ANNOUNCMENT_VISIBILITY_ERROR:'CHANGE_ANNOUNCMENT_VISIBILITY_ERROR',
    CHANGE_ANNOUNCMENT_VISIBILITY_SUCCESS:'CHANGE_ANNOUNCMENT_VISIBILITY_SUCCESS',
    EDIT_ANNOUNCMENT_BEGIN:'EDIT_ANNOUNCMENT_BEGIN',
    EDIT_ANNOUNCMENT_ERROR:'EDIT_ANNOUNCMENT_ERROR',
    EDIT_ANNOUNCMENT_SUCCESS:'EDIT_ANNOUNCMENT_SUCCESS',
    EDIT_ANNOUNCMENT_CONTENT_BEGIN:'EDIT_ANNOUNCMENT_CONTENT_BEGIN',
    EDIT_ANNOUNCMENT_CONTENT_ERROR:'EDIT_ANNOUNCMENT_CONTENT_ERROR',
    EDIT_ANNOUNCMENT_CONTENT_SUCCESS:'EDIT_ANNOUNCMENT_CONTENT_SUCCESS',
    DELETE_ANNOUNCMENT_BEGIN:'DELETE_ANNOUNCMENT_BEGIN',
    DELETE_ANNOUNCMENT_ERROR:'DELETE_ANNOUNCMENT_ERROR',
    DELETE_ANNOUNCMENT_SUCCESS:'DELETE_ANNOUNCMENT_SUCCESS',
    GET_ANNOUNCMENT_SETTINGS_BEGIN:'GET_ANNOUNCMENT_SETTINGS_BEGIN',
    GET_ANNOUNCMENT_SETTINGS_ERROR:'GET_ANNOUNCMENT_SETTINGS_ERROR',
    GET_ANNOUNCMENT_SETTINGS_SUCCESS:'GET_ANNOUNCMENT_SETTINGS_SUCCESS',
    SET_ANNOUNCMENT_SETTINGS_BEGIN:'SET_ANNOUNCMENT_SETTINGS_BEGIN',
    SET_ANNOUNCMENT_SETTINGS_ERROR:'SET_ANNOUNCMENT_SETTINGS_ERROR',
    SET_ANNOUNCMENT_SETTINGS_SUCCESS:'SET_ANNOUNCMENT_SETTINGS_SUCCESS',

    //DevTools
    DEV_TOOLS_ACTION_BEGIN:'DEV_TOOLS_ACTION_BEGIN',
    DEV_TOOLS_ACTION_SUCCESS:'DEV_TOOLS_ACTION_SUCCESS',
    DEV_TOOLS_ACTION_ERROR:'DEV_TOOLS_ACTION_ERROR',

    //SEO RULES
    ADD_SEO_RULE_BEGIN:'ADD_SEO_RULE_BEGIN',
    ADD_SEO_RULE_ERROR:'ADD_SEO_RULE_ERROR',
    ADD_SEO_RULE_SUCCESS:'ADD_SEO_RULE_SUCCESS',
    GET_SEO_RULES_BEGIN:'GET_SEO_RULES_BEGIN',
    GET_SEO_RULES_ERROR:'GET_SEO_RULES_ERROR',
    GET_SEO_RULES_SUCCESS:'GET_SEO_RULES_SUCCESS',
    GET_SEO_RULE_CONTENTS_BEGIN:'GET_SEO_RULE_CONTENTS_BEGIN',
    GET_SEO_RULE_CONTENTS_ERROR:'GET_SEO_RULE_CONTENTS_ERROR',
    GET_SEO_RULE_CONTENTS_SUCCESS:'GET_SEO_RULE_CONTENTS_SUCCESS',
    SEO_RULES_SECTION_STATE_BEGIN:'SEO_RULES_SECTION_STATE_BEGIN',
    SEO_RULES_SECTION_STATE_ERROR:'SEO_RULES_SECTION_STATE_ERROR',
    SEO_RULES_SECTION_STATE_SUCCESS:'SEO_RULES_SECTION_STATE_SUCCESS',
    SEO_RULE_STATE_BEGIN:'SEO_RULE_STATE_BEGIN',
    SEO_RULE_STATE_ERROR:'SEO_RULE_STATE_ERROR',
    SEO_RULE_STATE_SUCCESS:'SEO_RULE_STATE_SUCCESS',
    ADD_EDIT_SEO_RULE_META_BEGIN:'ADD_EDIT_SEO_RULE_META_BEGIN',
    ADD_EDIT_SEO_RULE_META_ERROR:'ADD_EDIT_SEO_RULE_META_ERROR',
    ADD_EDIT_SEO_RULE_META_SUCCESS:'ADD_EDIT_SEO_RULE_META_SUCCESS',
    ADD_EDIT_SEO_RULE_BLOCK_CONTENT_BEGIN:'ADD_EDIT_SEO_RULE_BLOCK_CONTENT_BEGIN',
    ADD_EDIT_SEO_RULE_BLOCK_CONTENT_ERROR:'ADD_EDIT_SEO_RULE_BLOCK_CONTENT_ERROR',
    ADD_EDIT_SEO_RULE_BLOCK_CONTENT_SUCCESS:'ADD_EDIT_SEO_RULE_BLOCK_CONTENT_SUCCESS'
};

export default ActionType;