import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function displayModeReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_DISPLAY_MODE_BEGIN:
        case ActionType.SET_DISPLAY_MODE_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_DISPLAY_MODE_ERROR:
        case ActionType.SET_DISPLAY_MODE_ERROR:
        case ActionType.SET_DISPLAY_MODE_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_DISPLAY_MODE_SUCCESS:
            return {
                ...state,
                inProcess: false,
                iconSetType: action.payload
            }
        default:
            return state;
    }
};


