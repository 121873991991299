import { ActionType } from '../constants/ActionTypes';
import config from '../constants/Configs';
import { Constants } from '../constants/Constants';
import { RestPath } from '../constants/SitePaths';
import { Screenshot } from '../services/screenshot/Screenshot'

import axios from 'axios';
import { LocalStorageService } from '../services/LocalStorageService';
import { SECTIONS } from '../constants/UIControlSections';

export const getUISettingsError = (data) => ({
    type: ActionType.GET_UI_SETTINGS_ERROR,
    payload: data
});
export const getUISettingsSuccess = (data) => ({
    type: ActionType.GET_UI_SETTINGS_SUCCESS,
    payload: data
});
export const getUISettingsBegin = () => ({
    type: ActionType.GET_UI_SETTINGS_BEGIN
});

export const setUISettingsBegin = () => ({
    type: ActionType.SET_UI_SETTINGS_BEGIN
});

export const setUISettingsSuccess = (data) => ({
    type: ActionType.SET_UI_SETTINGS_SUCCESS,
    payload: data
});

export const setUISettingsError = (data) => ({
    type: ActionType.SET_UI_SETTINGS_ERROR,
    payload: data
});

export const setUISetting = (data) => dispatch => {
    dispatch({
        type: ActionType.SET_UI_SETTING,
        payload: data
    });
    return Promise.resolve();
};

export const setUISettingsArray = (data) => dispatch => {
    dispatch({
        type: ActionType.SET_UI_SETTINGS,
        payload: data
    });
    return Promise.resolve();
};

export const resetUISettings = (data) => dispatch => {
    dispatch({
        type: ActionType.RESET_UI_SETTINGS,
        payload: data
    });
    return Promise.resolve();
};

export const setUIGroup = (data) => ({
    type: ActionType.SET_UI_GROUP,
    payload: data
});

//Change Wizard state of Registration Control
export const changeControlWizardStateBegin = () => ({
    type: ActionType.CHANGE_CONTROL_WIZARD_STATE_BEGIN
})

export const changeControlWizardStateError = (data) => ({
    type: ActionType.CHANGE_CONTROL_WIZARD_STATE_ERROR,
    payload: data
})

export const changeControlWizardStateSuccess = (data) => ({
    type: ActionType.CHANGE_CONTROL_WIZARD_STATE_SUCCESS,
    payload: data
})


export const changeControlVisibilityBegin = () => ({
    type: ActionType.CHANGE_CONTROL_VISIBILITY_BEGIN
})

export const changeControlVisibilityError = (data) => ({
    type: ActionType.CHANGE_CONTROL_VISIBILITY_ERROR,
    payload: data
})

export const changeControlVisibilitySuccess = (data) => ({
    type: ActionType.CHANGE_CONTROL_VISIBILITY_SUCCESS,
    payload: data
})

export const changeUIMenuLanguage = (data) => ({
    type: ActionType.CHANGE_MENU_LANGUAGE,
    payload: data
})

export const getPopUpsColorModeBegin = () => ({
    type: ActionType.GET_POPUPS_COLOR_MODE_BEGIN
});
export const getPopUpsColorModeError = (data) => ({
    type: ActionType.GET_POPUPS_COLOR_MODE_ERROR
});
export const getPopUpsColorModeSuccess = (data) => ({
    type: ActionType.GET_POPUPS_COLOR_MODE_SUCCESS,
    payload: data
});

export function changeControlVisibility(model, menuName, updateReducer) {
    const successData = {
        ...model,
        menuName: menuName
    }
    const apiUrl = getControlsApi(menuName)
    return dispatch => {
        dispatch(changeControlVisibilityBegin());
        return axios({
            url: config.BASE_URL + apiUrl,
            method: Constants.POST,
            data: JSON.stringify(model)
        })
            .then(response => !updateReducer && dispatch(changeControlVisibilitySuccess(successData)))
            .catch((error) => {
                dispatch(changeControlVisibilityError(error.response && error.response.data));
            });
    };
}

/* Get UI settings */
export function getUISettings(projectId) {
    return dispatch => {
        dispatch(getUISettingsBegin());
        const params = `?projectId=${projectId}`;
        return axios({
            url: config.BASE_URL + RestPath.GET_UI_SETTINGS_DATA + params,
            method: Constants.GET,
        })
            .then(response => dispatch(getUISettingsSuccess(response.data)))
            .catch((error) => {
                dispatch(getUISettingsError(error.response && error.response.data));
            });
    };
}

export function setUISettings(uiSettings, section) {
    let apiUrl = getSetUISettingsApi(section)
    return dispatch => {

        dispatch(setUISettingsBegin());
        return axios({
            url: config.BASE_URL + apiUrl,
            method: Constants.POST,
            data: JSON.stringify(uiSettings)
        })
            .then(response => {
                section?.indexOf('color') >= 0 && Screenshot.take(uiSettings.projectId);
                dispatch(setUISettingsSuccess(response.data))
            })
            .catch((error) => {
                dispatch(setUISettingsError(error.response && error.response.data));
            });
    };
}

export function changeControlWizardState(projectId, section, value) {

    const params = {
        projectId: projectId,
        section: section,
        value: value
    }

    return dispatch => {
        dispatch(changeControlWizardStateBegin());
        return axios({
            url: config.BASE_URL + RestPath.CHANGE_CONTROL_WIZARD_STATE,
            method: Constants.POST,
            data: JSON.stringify(params)
        })
            .then(response => dispatch(changeControlWizardStateSuccess(response.data)))
            .catch((error) => {
                dispatch(changeControlWizardStateError(error.response && error.response.data));
            });

    };
}

export function changeEditModeLanguage(languageId) {
    return dispatch => {
        dispatch(changeUIMenuLanguage(languageId));
    }
}

function getControlsApi(section) {
    let apiUrl = '';
    switch (section) {
        case SECTIONS.mainMenu:
        case SECTIONS.secondaryMenu:
        case SECTIONS.accountMenu:
        case SECTIONS.mobileAccountMenu:
        case SECTIONS.featuredMenu:
            apiUrl = RestPath.CHANGE_HEADER_CONTROL_VISIBILITY;
            break;
        case SECTIONS.footerMenu:
            apiUrl = RestPath.CHANGE_FOOTER_MENU_CONTROL_VISIBILITY;
            break;
        case SECTIONS.mobileNavBar:
            apiUrl = RestPath.CHANGE_MOBILE_NAVBAR_CONTROL_VISIBILITY;
            break;
        case SECTIONS.floatingMenu:
            apiUrl = RestPath.CHANGE_FLOATING_MENU_CONTROL_VISIBILITY;
            break;
        case SECTIONS.desktopNavBarMenu:
            apiUrl = RestPath.CHANGE_DESKTOPNAVBAR_MENU_VISIBILITY;
            break;
        case SECTIONS.registrationForm:
        case SECTIONS.loginForm:
        case SECTIONS.forgotPasswordForm:
        case SECTIONS.recoverPasswordForm:
        case SECTIONS.account:
            apiUrl = RestPath.CHANGE_FORM_CONTROL_VISIBILITY;
            break;
        case SECTIONS.rules:
            apiUrl = RestPath.CHANGE_RULES_CONTROL_VISIBILITY;
            break;
        default:
            apiUrl = RestPath.CHANGE_CONTROL_VISIBILITY;
            break;
    }
    return apiUrl;
}

function getSetUISettingsApi(section) {
    let apiUrl = '';
    switch (section) {
        case 'popupColor':
            apiUrl = RestPath.SET_POPUP_COLOR_SETTINGS;
            break;
        case 'color':
            apiUrl = RestPath.SET_COLOR_SETTINGS;
            break;
        case 'button':
            apiUrl = RestPath.SET_BUTTONS_SETTINGS;
            break;
        case 'input':
            apiUrl = RestPath.SET_FORM_INPUTS_SETTINGS;
            break;
        case 'font':
            apiUrl = RestPath.SET_FONT_SETTINGS;
            break;
        case 'popupColor':
            apiUrl = RestPath.SET_POPUP_COLOR_MODE;
            break;
        case 'colorMode':
            apiUrl = RestPath.SET_COLOR_MODE;
            break;
        default:
            apiUrl = RestPath.SET_UI_SETTINGS;
            break;
    }
    return apiUrl;
}

export function getPopUpsColorMode() {
    return dispatch => {
        dispatch(getPopUpsColorModeBegin());

        return axios({
            url: config.BASE_URL + RestPath.GET_POPUP_COLOR_MODE,
            method: Constants.GET
        }).then(response => {
            dispatch(getPopUpsColorModeSuccess(response.data))
            return response.data;
        })
            .catch((error) => {
                dispatch(getPopUpsColorModeError(error));
            });
    };
}

export function checkForChallange(domain) {
    return dispatch => {
        const headers = {
            'Content-Type': 'text/plain'
        };
        return axios({
            url: "//" + domain + '/siteapi/HealthCheck/IsAlive',
            method: Constants.GET,
            headers,
        })
            .catch((error) => {
                if (error.message === "Network Error") {
                    let underAttack = LocalStorageService.get("under_attack")
                    if (!underAttack) {
                        LocalStorageService.set("under_attack", true, { ttl: 30 })
                        const challengeUrl = `https://${domain}/siteapi/challenge/processing`
                        window.location.href = challengeUrl
                    }
                }
            });
    };
}

export const setColorModeSettings = (data) => ({
    type: ActionType.SET_COLOR_MODE_SETTINGS,
    payload: data
});

export const changeWebsiteColorMode = (data) => ({
    type: ActionType.CHANGE_WEBSITE_COLOR_MODE,
    payload: data
});

export function getColorModeSettings() {
    return dispatch => {

        return axios({
            url: config.BASE_URL + RestPath.GET_COLOR_MODE_SETTINGS,
            method: Constants.GET
        })
            .then(response => {
                dispatch(setColorModeSettings(response.data));
                return response.data;
            })
    };
}


export const changeBrandingSettingsState = (data) => ({
    type: ActionType.CHANGE_BRANDING_SETTINGS_STATE,
    payload: data
});


export const setBrandingInitialSettings = (data) => ({
    type: ActionType.SET_BRANDING_INITIAL_SETTINGS,
    payload: data
})

export function changeMenuItemPinMode(model) {

    return dispatch => {
        dispatch(changeControlVisibilityBegin());
        return axios({
            url: config.BASE_URL + RestPath.CHANGE_MENU_PIN_MODE,
            method: Constants.POST,
            data: JSON.stringify(model)
        })
            .then(response => {
                return response.data;
            })
            .catch((error) => {

            });
    };
}

export const rulesButtonsVisibilityBegin = () => ({
    type: ActionType.RULES_BUTTONS_VISIBILITY_BEGIN
})

export const rulesButtonsVisibilityError = (data) => ({
    type: ActionType.RULES_BUTTONS_VISIBILITY_ERROR,
})

export const rulesButtonsVisibilitySuccess = (data) => (
    {   
    type: ActionType.RULES_BUTTONS_VISIBILITY_SUCCESS,
    payload: data
})

export const getRulesButtonsVisibility = () => {
    return dispatch => {
        dispatch(rulesButtonsVisibilityBegin());

        return axios({
            url: config.BASE_URL + RestPath.GET_RULES_BUTTONS_VISIBILITY,
            method: Constants.GET
        })
        .then(response => {
            dispatch(rulesButtonsVisibilitySuccess(response.data?.showPrintDownloadButton))
            return response.data?.showPrintDownloadButton;
        })
        .catch((error) => {
            dispatch(rulesButtonsVisibilityError(error));
        });
    }
}

export const changeRulesButtonsVisibility = (data) => {
    return dispatch => {
        dispatch(rulesButtonsVisibilityBegin());

        return axios({
            url: config.BASE_URL + RestPath.GHANGE_RULES_BUTTONS_VISIBILITY,
            method: Constants.POST,
            data: JSON.stringify(data)
        })
        .then(response => {
            dispatch(rulesButtonsVisibilitySuccess(data.showPrintDownloadButton))
            return data.showPrintDownloadButton;
        })
        .catch((error) => {
            dispatch(rulesButtonsVisibilityError());
        });
    }
}